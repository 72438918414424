import React, { useState } from "react";
import { Breadcrumb, Button, Col, Input, Row, Table, TableProps } from "antd";
import AddEditIssue from "./AddEditIssue";
import { useGetAllIssueList } from "../../Services/RQMastersService";
import "../../common/common.css";

import {
  ArrowLeftOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
const { Search } = Input;
// import HTTPSCalls from '../../Services/HTTPCalls';

const IssueList = () => {
  const {
    isLoading,
    data: issueData,
    isFetching,
  } = useGetAllIssueList("?start=0&length=100");
  const [showAddEditIssue, setShowAddEditIssue] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");

  const onEdit = (record: React.SetStateAction<null>) => {
    setSelectedIssue(record);
    setShowAddEditIssue(true);
  };
  const capitalizeFirstLetter = (str: string) =>
    str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();

  const filteredData = issueData?.result
    ?.filter((item: { name: string }) =>
      item?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
    )
    ?.map((item: { name: string }) => ({
      ...item,
      name: capitalizeFirstLetter(item?.name),
    }));

  // const GetIssueType = async () => {
  //     let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.issueType.getAll + "?", { start: 0, length: 100 });
  //     if (response.result.length > 0)
  //         setIssues(response.result)
  // }
  // useEffect(() => {
  //     GetIssueType()
  // }, [taskid])

  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "9%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: any, record: any) => text,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "description",
      render: (text: any, record: any) => (
        <>
          <Row justify={"end"}>
            <FormOutlined
              className="capTask-edit-btn"
              onClick={() => onEdit(record)}
            />
            {/* <DeleteOutlined className="capTask-delete-btn"/> */}
          </Row>
        </>
      ),
    },
  ];
  const handleSearchInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchFilter(event.target.value);
  };
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });

    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Issue",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Button
              icon={<PlusOutlined />}
              className="capTask-btn btn-clr-primary btn-primary-md mb-1"
              size="small"
              onClick={() => setShowAddEditIssue(true)}
            >
              Issue
            </Button>

            <Search
              size="small"
              placeholder="Search..."
              allowClear
              onChange={handleSearchInputChange}
              style={{ width: 175 }}
              className="mb-1"
            />
          </Row>
        </Col>
        <Col lg={24}>
          {isLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              className="capTask-custom-table mt-3 project-table"
              size="small"
              onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              scroll={{ y: 500 }}
              columns={columns}
              // dataSource={filteredData}
              dataSource={filteredData?.map((data: any, index: number) => ({
                serial: index + 1,
                key: index,
                ...data,
              }))}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingTop: "40px", width: 140 }}
                    // src={process.env.PUBLIC_URL + "/noTask.svg"}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>
      {showAddEditIssue && (
        <AddEditIssue
          onDismiss={() => {
            setShowAddEditIssue(false);
            setSelectedIssue(null);
          }}
          data={selectedIssue}
        />
      )}
    </>
  );
};

export default IssueList;
