import { useMutation, useQuery } from "@tanstack/react-query"
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";

export const useTaskListByBoard = (payload:any = undefined)=>{
     return useQuery({ 
      enabled:!!payload?.project?.id,
      queryKey: ["useTaskListByBoard",payload],
      queryFn: () => getTaskListByBoard(payload),
    })
  }
export const useTaskListByBacklog = (payload:any = undefined)=>{
     return useQuery({ 
      enabled:!!payload?.project?.id,
      queryKey: ["useTaskListByBacklog",payload],
      queryFn: () => getTaskListByBacklog(payload),
    })
  }

export const useUpdateTaskStatus = ()=>{
  return useMutation({
    mutationFn: async (payload:any) => {
      const result = await updateTaskStatus(payload);
      return result
    },
    onSuccess: (data: any) => {
      if (!data?.errors) {
        queryClient.invalidateQueries({
          queryKey: ["taskGetById"],
        })
        queryClient.invalidateQueries({
          queryKey: ["useTaskListByBoard"],
        })
        queryClient.invalidateQueries({
          queryKey: ["useTaskListByBacklog"],
        })
      }
    }
  })
}
export const useUpdateSprint = ()=>{
  return useMutation({
    mutationFn: async (payload:any) => {
      const result = await updateTaskSprint(payload);
      return result
    },
    onSuccess: (data: any) => {
      if (!data?.errors) {
        queryClient.invalidateQueries({
          queryKey: ["taskGetById"],
        })
        queryClient.invalidateQueries({
          queryKey: ["useTaskListByBoard"],
        })
        queryClient.invalidateQueries({
          queryKey: ["useTaskListByBacklog"],
        })
      }
    }
  })
}
export const useDeleteTask = ()=>{
  return useMutation({
    mutationFn: async (payload:any) => {

      
      const result = await deleteTask(payload);
      return result
    },
    onSuccess: (data: any) => {  
      if (!data?.errors) {
        queryClient.invalidateQueries({
          queryKey: ["getInfiniteLogList"],
        })
        queryClient.invalidateQueries({
          queryKey: ["ReportTasksListOptions"],
        })
        queryClient.invalidateQueries({
          queryKey: ["useTaskListByBoard"],
        })
        queryClient.invalidateQueries({
          queryKey: ["useTaskListByBacklog"],
        })
      }
    }
  })
}

const updateTaskStatus = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.updateStatus+"?",{}, {
    ...payload,
  });
const updateTaskSprint = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.updateTaskSprint, {
    ...payload,
  });
const deleteTask = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.deleteTask,{
    ...payload,
  });

  const getTaskListByBoard = async (payload: any) =>
    await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.board.taskListByBoard, {
      ...payload,
      projectId:payload?.project?.id
    });
  const getTaskListByBacklog = async (payload: any) =>
    await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.backLog.taskListByBacklog, {
      ...payload,
      projectId:payload?.project?.id
    });