import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";

export const useReportTasks = (payload: any = undefined) => {
  return useQuery({
    // enabled: !!payload?.projectId,
    queryKey: ["ReportTasksListOptions", payload],
    queryFn: async () => await getReportTasks(payload),
  });
  
};

const getReportTasks = async (payload: any) =>
{
  
  return  await HTTPSCalls.POST(
    HTTPSCalls.ENDPOINTS.task.issues + "?",
    {
      ...payload,
    },
    {
       start: payload?.start,
      // start: 0,
      length: payload?.length,
      // length: 500,
    }
  )
}
  