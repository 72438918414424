import * as React from "react";
import {
  Row,
  Col,
  Card,
  Select,
  Typography,
  ConfigProvider,
  Progress,
  message,
} from "antd";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import {
  projectData as PD,
  projectData,
  setProjectData,
} from "../../store/slices/project-slice";
import { useDispatch } from "react-redux";
import TaskReporSkeleton from "../dashboard/Common/TaskReporSkeleton";
import {
  useGetProjects,
  useTaskReportList,
} from "../../Services/RQDashboardService";
import { Link } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import { AddEditApis } from "../../Services/RQTaskService";
import { dayjs } from "../../utils/dayjs";
interface ITaskReport{
  onProjectChange:(project:{id:string,name:string})=>void;
  project:{
    label:string,
    value:string
  }
}
const TaskReport = ({onProjectChange,project}: ITaskReport) => {
  const user = useSelector(userData);
  const [selectedProject, setSelectedProject] = React.useState<{
    label: string;
    value: string;
  }>({...project});
  const { data: taskReportData, isFetching: isTaskReportFetching } =
    useTaskReportList(selectedProject?.value);
  const taskReport = React.useMemo(() => {
    if (taskReportData?.message)
      message.error(`Error in Task Report => ${taskReportData?.message}`);
    return taskReportData?.result || [];
  }, [taskReportData]);

  const [searchFilter, setSearchFilter] = React.useState({
    project: {
      search: "",
      length:200
    },
  });
  const { useProject } = AddEditApis;
  const { data: projectDataList, isFetching: isProjectFetching } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = React.useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);

  const data = React.useMemo(
    () => [
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 0)
            ?.totalCount || 0,
        name: "Backlog",
        itemStyle: { color: "#7690ff" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 1)
            ?.totalCount || 0,
        name: "ToDo",
        itemStyle: { color: "#8DD6FF" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 2)
            ?.totalCount || 0,
        name: "Need to Discuss",
        itemStyle: { color: "#5FAFFF" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 3)
            ?.totalCount || 0,
        name: "In Progress",
        itemStyle: { color: "#27B7FF" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 7)
            ?.totalCount || 0,
        name: "Developed",
        itemStyle: { color: "#ff76af" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 4)
            ?.totalCount || 0,
        name: "UAT",
        itemStyle: { color: "#D3A8FD" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 5)
            ?.totalCount || 0,
        name: "Testing",
        itemStyle: { color: "#FFB579" },
      },
      {
        value:
          taskReport?.statusWise?.find((x: any) => x?.status === 6)
            ?.totalCount || 0,
        name: "Done",
        itemStyle: { color: "#4FD190" },
      },
    ],
    [taskReport]
  );

  const option = React.useMemo(
    () => ({
      calculable: true,
      colorBy: "data",
      padding: 5,
      tooltip: { trigger: "item" },
      legend: {
        top: "76%",
        left: "right",
        icon: "circle",
        textStyle: { fontSize: 9.8 },
      },
      series: [
        {
          name: "",
          type: "pie",
          bottom: 26,
          top: -30,
          radius: ["40%", "66%"],
          avoidLabelOverlap: false,
          label: { show: false, position: "center" },
          emphasis: { label: { show: true, fontSize: 12 } },
          labelLine: { show: false },
          data: data,
        },
      ],
    }),
    [taskReport]
  );

  const priorityTotalCount = React.useMemo(() => {
    return taskReport?.priorityWise?.reduce(
      (acc: any, current: any) => acc + current?.totalCount,
      0
    );
  }, [taskReport]);

  const high = React.useMemo(
    () =>
      taskReport?.priorityWise?.find((x: any) => x?.status === 2)?.totalCount ||
      0,
    [taskReport]
  );
  const medium = React.useMemo(
    () =>
      taskReport?.priorityWise?.find((x: any) => x?.status === 1)?.totalCount ||
      0,
    [taskReport]
  );
  const low = React.useMemo(
    () =>
      taskReport?.priorityWise?.find((x: any) => x?.status === 0)?.totalCount ||
      0,
    [taskReport]
  );
  React.useEffect(()=>{
    const p = projectOptions?.[0]
    if(!!!selectedProject?.value && !!p)
    {
      setSelectedProject((pre:any)=>({
        ...pre,
        ...p
      }));
      onProjectChange({
        id:p?.value,
        name:p?.label
      });
    }
  },[projectOptions])

  React.useEffect(()=>{
    if(!!selectedProject?.value)
    {
      onProjectChange({
        id:selectedProject?.value,
        name:selectedProject?.label
      });
    }
  },[selectedProject])
  
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorTextPlaceholder: "#95a5a6",
              fontSize: 14,
              colorPrimaryHover: "none",
              fontSizeIcon: 11,
              paddingSM: 0,
              paddingXS: 0,
              paddingXXS: 0,
              lineWidth: 1,
              borderRadius: 0,
              borderRadiusLG: 0,
              borderRadiusSM: 0,
              borderRadiusXS: 0,
              controlPaddingHorizontal: 12,
              controlPaddingHorizontalSM: 12,
              controlHeight: 27,
              controlHeightLG: 27,
              algorithm: true,
              fontWeightStrong: 600,
            },
          },
        }}
      >
        {/* {isTaskReportFetching ? (
          <TaskReporSkeleton />
        ) : ( */}
          <Card
            title={
              <Row align={"middle"} justify={"space-between"}>
                <Col span={3}>
                  <Typography.Title level={4} className="fw600 fs13-5">
                    Task Report
                  </Typography.Title>
                </Col>
                <Col>
                  <Link
                    className="cursor fs13-5"
                    style={{ lineHeight: "23px" }}
                    to="/taskboard"
                  >
                    View all
                  </Link>
                </Col>
              </Row>
            }
            className="cardtaskbord Resposivecostom mr-3 "
          >
            <Row gutter={[0, 0]}>
              <Col span={12} md={12} className="pb-2">
                <ReactEcharts option={option} className="pie-chart" />
              </Col>
              <Col className="pl-0 mt-5 pt-4" span={12} lg={11} md={10}>
                <Select
                  loading={isProjectFetching}
                  key={dayjs().format('[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]')}
                  labelInValue
                  className="capTask-custom-select w100"
                  placeholder="Select a Project"
                  options={projectOptions}
                  showSearch
                  onSearch={(value) => {
                    // setSearchFilter((pre: any) => ({
                    //   ...pre,
                    //   project: {
                    //     search: value,
                    //   },
                    // }));
                  }}

                  onBlur={() => {
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      project: {
                        search: "",
                        length:200
                      },
                    }));
                  }}
                  defaultValue={
                    !!selectedProject?.value
                    ?selectedProject
                    :projectOptions?.[0]
                  }
                  optionFilterProp="label"
                  onSelect={(value: any) => {
                    setSelectedProject({
                      label: value?.label,
                      value: value?.value,
                    });
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      project: {
                        search: "",
                        length:200
                      },
                    }));
                  }}
                />
                <Row
                  gutter={[0, 0]}
                  justify={"space-between"}
                  align={"middle"}
                  className="mt-5"
                >
                  <Col span={6}>
                    <Typography.Text>High</Typography.Text>
                  </Col>
                  <Col span={11}>
                    <Progress
                      strokeColor={"#52c41a"}
                      showInfo={false}
                      percent={high ? (high / priorityTotalCount) * 100 : 0}
                      status="active"
                    />
                  </Col>
                  <Col span={7} className="text-end">
                    {high}
                  </Col>
                </Row>
                <Row
                  gutter={[0, 0]}
                  justify={"space-between"}
                  align={"middle"}
                  className="mt-5"
                >
                  <Col span={6}>
                    <Typography.Text  ellipsis>Medium</Typography.Text>
                  </Col>
                  <Col span={11}>
                    <Progress
                      showInfo={false}
                      percent={medium ? (medium / priorityTotalCount) * 100 : 0}
                      status="active"
                    />
                  </Col>
                  <Col span={7} className="text-end">
                    {medium}
                  </Col>
                </Row>
                <Row
                  gutter={[0, 0]}
                  justify={"space-between"}
                  align={"middle"}
                  className="mt-5"
                >
                  <Col span={6}>
                    <Typography.Text>Low</Typography.Text>
                  </Col>
                  <Col span={11}>
                    <Progress
                      strokeColor={"#ff4d4f"}
                      showInfo={false}
                      percent={low ? (low / priorityTotalCount) * 100 : 0}
                      status="active"
                    />
                  </Col>
                  <Col span={7} className="text-end">
                    {low}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        {/* )} */}
      </ConfigProvider>
    </>
  );
};

export default TaskReport;
