import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Input, Row, Select } from "antd";
import "../../common/common.css";
export const ToothbarReport = ({
  setprojectuserarr,
  projectuserarrfilter,
  setdefaultCount,
  defaultCount,
  setReloadOutlined,
}: any) => {
  const handlesearchfilter = (value: string) => {
    let filtereddata = projectuserarrfilter?.filter((item: any) => {
      if (
        item?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase())
      ) {
        return item;
      }
    });
    setprojectuserarr(filtereddata);
  };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#d9d9d9",
            fontSize: 14,
            colorPrimaryHover: "none",
            fontSizeIcon: 12,
            paddingSM: 8,
            paddingXS: 8,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 28,
            controlHeightLG: 27,
            algorithm: true,
            fontWeightStrong: 600,
          },
          Input: {
            controlHeight: 27,
            fontSizeIcon: 12,
            controlHeightLG: 27,
            inputFontSize: 14,
            colorTextPlaceholder: "#d9d9d9",
          },
        },
      }}
    >
      <Row gutter={[16, 16]} align="middle" justify={"space-between"}>
        <Col span={6}>
          <Input
            placeholder="Search..."
            allowClear
            onChange={(e: any) => handlesearchfilter(e?.target?.value)}
            type="text"
            style={{ border: "1px solid #d9d9d9", width: "190px" }}
            suffix={
              <SearchOutlined
                style={{
                  color: "rgba(0, 0, 0, 0.45)",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              />
            }
          />
        </Col>

        <Col>
          <Select
            className="capTask-custom-select"
            style={{
              width: 180,
              margin: "0px 20px",
            }}
            value={defaultCount}
            options={[
              { label: "All", value: 0 },
              { label: "Active", value: 1 },
              { label: "InActive", value: 2 },
            ]}
            onChange={(item: any) => {
              setdefaultCount(item);
            }}
            showSearch
            onSearch={onSearch}
            optionFilterProp="label"
          />
          <Button
            style={{ height: 28 }}
            onClick={() => {
              setReloadOutlined((prevState: any) => ({
                ...prevState,
              }));
            }}
            icon={
              <ReloadOutlined
                style={{ color: "rgb(61, 153, 37)", fontSize: "14px" ,  }}
              />
            }
          />
        </Col>
      </Row>
    </ConfigProvider>
  );
};
