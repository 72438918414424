import {
  Row,
  Col,
  Button,
  Form,
  TreeSelect,
  ConfigProvider,
  Select,
  Input,
} from "antd";
import {
  isUserAdmin,
  isUserTeamLeader,
  statusconvertor,
} from "../../utils/Util";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { AddEditApis } from "../../Services/RQTaskService";
import { useMemo, useState } from "react";

interface Props {
  page: any;
  onSearchbar: () => void;
  statusFilter: any;
  setStatusFilter: any;
  setUserRelatedTasksFilter: any;
  setSelectedUser: any;
  userId: any;
  setNameUkeySearch:any
}

const Filter = ({
  page,
  statusFilter,
  setUserRelatedTasksFilter,
  setStatusFilter,
  onSearchbar,
  setSelectedUser,
  userId,
setNameUkeySearch
}: Props) => {
  const { useUser } = AddEditApis;
  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields().then(() => {
      onSearchbar();
    });
  };
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  const [searchFilter, setSearchFilter] = useState<{
    user: {
      search: undefined;
    };
  }>({
    user: {
      search: undefined,
    },
  });
  const { data: userDataList, isPending: isUserPending } =
    useUser(searchFilter);

  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                colorTextPlaceholder: "#95a5a6",
                fontSize: 14,

                colorPrimaryHover: "none",
                fontSizeIcon: 12,
                paddingSM: 8,
                paddingXS: 8,
                paddingXXS: 4,
                lineWidth: 1,
                borderRadius: 4,
                borderRadiusLG: 4,
                borderRadiusSM: 4,
                borderRadiusXS: 4,
                controlPaddingHorizontal: 4,
                controlPaddingHorizontalSM: 4,
                controlHeight: 28,
                controlHeightLG: 27,
                algorithm: true,
                fontWeightStrong: 600,
              },
              DatePicker: {
                controlHeight: 27,
                controlHeightLG: 27,
              },
              Input: {
                controlHeight: 27,
                controlHeightLG: 27,
              },
            },
          }}
        >
          <Row justify={"space-between"} align={"middle"}>
            <Col span={19}>
              <TreeSelect
                className="issueReport-custom-select ml-5"
                style={{
                  width: 180,
                }}
                treeData={[
                  { title: statusconvertor(1), value: 1 },
                  { title: statusconvertor(2), value: 2 },
                  { title: statusconvertor(3), value: 3 },
                  { title: statusconvertor(7), value: 7 },
                  { title: statusconvertor(4), value: 4 },
                  { title: statusconvertor(5), value: 5 },
                  { title: statusconvertor(6), value: 6 },
                ]}
                value={statusFilter}
                treeCheckable
                showCheckedStrategy="SHOW_PARENT"
                placeholder="Status Filter"
                maxTagCount={1}
                onChange={setStatusFilter}
                onSearch={onSearch}
                treeNodeFilterProp="title"
              />
              {(isUserAdmin() || isUserTeamLeader()) && (
                <Select
                  labelInValue
                  className="issueReport-custom-select ml-3"
                  style={{ width: 200 }}
                  placeholder="Select assignee"
                  showSearch
                  maxTagCount={"responsive"}
                  options={reporterOptions}
                  optionFilterProp="label"
                  allowClear
                  onClear={() => {
                    setSelectedUser("");
                    setUserRelatedTasksFilter((prevState: any) => ({
                      ...prevState,
                      projectid: "",
                      userid: userId,
                    }));
                  }}
                  onSelect={(value) => {
                    setSelectedUser(value?.value);
                    setUserRelatedTasksFilter((prevState: any) => ({
                      ...prevState,
                      projectid: "",
                      userid: value?.value,
                    }));
                  }}
                  onSearch={(value) => {
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      user: {
                        search: value,
                      },
                    }));
                  }}
                />
              )}
              <Input
                className="custom-input issueReport-custom-select ml-3"
                style={{ width: 210 }}
                onChange={(e:any)=>{
                  setNameUkeySearch(e?.target?.value)
                }}
                suffix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0, 0, 0, 0.45)",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  />
                }
                placeholder="Name & Ukey Search..."
                allowClear
              />
            </Col>

            <Col>
              <Button
                className="pl-5 pr-5"
                type="primary"
                style={{ borderRadius: "3px" }}
                htmlType="submit"
                size={"small"}
              >
                Search
              </Button>
              {/* </Form.Item> */}

              <Button
                className="ml-8 "
                style={{ height: 24, marginTop: 4, cursor: "pointer" }}
                onClick={() => {
                  setUserRelatedTasksFilter((prevState: any) => ({
                    ...prevState,
                    projectid: "",
                  }));
                }}
                icon={
                  <ReloadOutlined
                    style={{ color: "rgb(61, 153, 37)", fontSize: 14 }}
                  />
                }
              />
            </Col>
          </Row>
        </ConfigProvider>
      </Form>
    </div>
  );
};

export default Filter;
