import {
  Col,
  Input,
  Row,
  Table,
  TableColumnsType,
  TableProps,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useState } from "react";
import "../../common/common.css";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";

function SummeryTable({
  WeeklyReason,
  isWeeklyReasonPending,
  listParams,
  setListParams,
}: any) {
  const [refresh, setRefresh] = useState(false);

  const changeListParams = (key: string, value: any) => {
    setListParams((prev: any) => ({ ...prev, [key]: value }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  const handleSearch = useCallback(
    _.debounce((value: string) => {
      setListParams((prev: any) => ({
        ...prev,
        UserNameSearch: value,
      }));
    }, 300),
    []
  );

  const columns: TableColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: 10,
      ellipsis: true,
      render: (text: string) => <div>{text}</div>,
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: 30,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.createdBy?.userName.localeCompare(b.createdBy?.userName),

      render: (text: string, record: any, index: number) => (
        <div>{record?.createdBy?.userName}</div>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 100,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.createdBy?.userName.localeCompare(b.createdBy?.userName),
      render: (text: string) => (
        <Typography.Paragraph ellipsis>{text}</Typography.Paragraph>
      ),
    },
    {
      title: <Typography.Text ellipsis>Date of Creation</Typography.Text>,
      dataIndex: "updatedBy",
      key: "updatedBy",
      width: 20,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.createdBy?.date.localeCompare(b.createdBy?.date),
      render: (task, record: any) => (
        <Typography.Text ellipsis style={{ fontSize: "small" }}>
          <Tag bordered={false} color="processing">
            {record?.createdBy?.date
              ? moment(record?.createdBy?.date).format("DD MMM YYYY")
              : "N/A"}
          </Tag>
        </Typography.Text>
      ),
    },
  ];

  return (
    <div className="mt-2 mr-4 ml-4">
      {isWeeklyReasonPending ? (
        <TableSkeleton columns={columns} scroll={{ y: "75vh" }} />
      ) : (
        <>
          <Row className="mb-2 " justify={"space-between"}>
            <Col span={1}></Col>
            <Col>
              <Input
                style={{ height: 25, width: 200 }}
                suffix={<SearchOutlined />}
                placeholder="Search..."
                allowClear
                onChange={(e: any) => handleSearch(e?.target?.value)}
                type="text"
              />
            </Col>
          </Row>
          <Table
            scroll={{ y: "75vh" }}
            columns={columns}
            dataSource={
              WeeklyReason?.map((data: any, index: number) => ({
                serial: index + 1,
                key: index,
                ...data,
              })) || []
            }
            onChange={onChangeTable}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
            className="capTask-custom-table"
            locale={{
              emptyText: (
                <Typography.Title
                  level={4}
                  style={{ textAlign: "center", paddingTop: "47px" }}
                >
                  <img
                    style={{ width: 140, paddingBottom: 15 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                </Typography.Title>
              ),
            }}
          />
        </>
      )}
    </div>
  );
}

export default SummeryTable;
