import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Spin,
  message,
  Tooltip,
  Typography,
  Select,
  Tag,
  Form,
} from "antd";
import { useSelector } from "react-redux";
import {
  boardViewMode as BVM,
  projectData,
} from "../store/slices/project-slice";
import { CopyOutlined, LineOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  getPriorityColor,
  priorityNameObj,
  taskStatusNameObj,
} from "../utils/Util";
import { Link } from "react-router-dom";
import AddEditTask, { TaskStatusOptions } from "../common/AddEditTask";
import {
  useDeleteTask,
  useTaskListByBoard,
  useUpdateTaskStatus,
} from "../Services/RQBoardService";

import { AssigneeAvatar } from "./dashboard/Common/AssigneeAvatar";
import moment from "moment";
import { IDragEnd } from "../components/dnd/DndTask";
import TaskSearchFilter, {
  ITaskFilter,
  ITaskViewMode,
} from "../components/dnd/TaskSearchFilter";
import { boardType, setBoardType } from "../store/slices/board-slice";
import { useSaveTaskLog } from "../Services/RQLogService";
import { userData } from "../store/slices/user-slice";
import BoardBacklogFrameLoading from "../common/Loading-components/BoardBacklogFrameLoading";
import DeleteTaskModel from "../components/DeleteTaskModel";
const DndTask = React.lazy(() => import("../components/dnd/DndTask"));
const ProjectTimeline = React.lazy(() => import("../common/ProjectTimeline"));

const Board: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const boardView = useSelector(boardType);
  const project = useSelector(projectData);
  const user = useSelector(userData);
  const { mutateAsync: taskLogMutateAsync, isPending: isTaskLogs } =
    useSaveTaskLog("getInfiniteLogList");
  const { mutateAsync, isPending: isUpdateTaskStatus } = useUpdateTaskStatus();
  const { mutateAsync: deleteTaskAsync, isPending: isDeleteTask } =
    useDeleteTask();
  const [taskSearchFilter, setTaskSearchFilter] = useState<ITaskFilter>({
    project: undefined,
    date: undefined,
    priority: undefined,
    searchIn: undefined,
    sprintIds: undefined,
    teamMemberIds: undefined,
    searchText: undefined,
  });
  const includeFields = useMemo(() => ["1", "2", "3", "4", "5", "7"], []);
  const { data: taskData, isFetching: isFetchingTaskList } =
    useTaskListByBoard(taskSearchFilter);
  const primaryApiData = useMemo(() => {
    try {
      if (!loading) {
        let data: any[] = [];
        if (!!taskData?.message)
          message.error(
            `Error in Get Task List in Board => ${taskData?.message}`
          );
        // convert data
        for (let key in taskStatusNameObj) {
          if (!!includeFields?.find((x: any) => x === key)) {
            let t = taskData?.result?.find(
              (x: any) => String(x?.status) === key
            );
            if (!!!t)
              data.push({
                tasks: [],
                status: key,
                key: `status-group-${key}`,
              });
            else
              data.push({
                tasks: t?.tasks || [],
                key: `status-group-${key}`,
                status: String(t?.status),
              });
          }
        }
        return data || [];
      }
      return [];
    } catch (e) {
      message.error(`Error in primaryApiData => ${e}`);
      console.error(`Error in primaryApiData => ${e}`);
      return taskData?.result || [];
    }
  }, [taskData, loading]);
  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);
  const breadcrumbItems = useMemo(
    () => [
      {
        key: "Home",
        title: (
          <Link to={"/dashboard"} className="BreadcrumbLink">
            Home
          </Link>
        ),
      },
      {
        key: "Board",
        title: "Board",
        // href: "/board",
      },
    ],
    []
  );
  const [deleteTaskData, setDeleteTaskData] = useState<
    { id: string; ukey: string } | undefined
  >(undefined);

  const onStatusSelect = useCallback(async (value: any, task: any) => {
    if (Number(value?.value) === Number(task?.tStatus)) return;

    if (value?.value === 9) {
      setDeleteTaskData({
        id: task?.id,
        ukey: task?.uKey,
      });
    } else {
      const x: IDragEnd = {
        data: {
          taskId: task?.id,
          taskStatus: value?.value,
        },
        success: true,
      };
      await onDragEnd(x, task);
    }
  }, []);

  const listStyle = useMemo(() => {
    return {
      backgroundColor: "white",
      padding: "4px 0px",
      borderRadius: 4,
    };
  }, []);
  const taskSubFilterStyle = useMemo(() => {
    return {
      backgroundColor: "rgba(247, 248, 255, 0.5)",
      borderRadius: 4,
      marginBottom: 8,
    };
  }, []);
  const TaskView: React.FC<{ task: any; view: string }> = memo(
    ({ task, view }) => {
      return (
        <Row
          key={`Task-${task?.id}`}
          gutter={[0, 0]}
          align={"middle"}
          justify={view === "list" ? "space-around" : "space-around"}
          className={`ms-2 me-2 cursorgrab BacklogBoard   ${
            view === "list" ? "mt-1" : "my-3"
          }`}
          style={
            view === "list"
              ? listStyle
              : {
                  padding: "2px 8px",
                  borderRadius: 4,
                  backgroundColor: "white",
                }
          }
        >
          <Col
            lg={5}
            xl={view === "list" ? 1 : 12}
            md={5}
            className={view === "list" ? "o1" : "o3 mt-2"}
          >
            <Link to={`/task/${task?.id}`}>
              <Tag bordered={false} color="magenta">
                {task?.uKey}
              </Tag>
            </Link>
          </Col>

          <Col
            lg={5}
            md={5}
            xl={view === "list" ? 4 : 11}
            className={view === "list" ? "o2" : "o1 mt-1"}
          >
            <Typography.Link
              ellipsis
              onClick={() => {
                setSelectedRecord(task);
              }}
              title={task?.title}
            >
              <span style={{ color: "#1677ff" }}>{task?.title}</span>
            </Typography.Link>
          </Col>
          <Col
            lg={4}
            md={3}
            xl={view === "list" ? 3 : 12}
            className={`HideBoard ${view === "list" ? "o3" : "o2 mt-1"}`}
          >
            <Select
              // key={Date().toString()}
              labelInValue
              className="w100 Board-Backlog"
              variant="borderless"
              size="small"
              options={TaskStatusOptions}
              defaultValue={task?.tStatus}
              showSearch
              filterOption={(input, option: any) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
              }
              onSelect={(value) => onStatusSelect(value, task)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col
            lg={2}
            xl={view === "list" ? 2 : 11}
            md={3}
            className={`HideBoard cursor ${view === "list" ? "o4" : "o6 mt-2"}`}
          >
            <AssigneeAvatar data={task?.assignee} />
          </Col>
          {view === "list" ? (
            <Col lg={2} md={3} xl={1} className="cursorPointer HideBoard o5">
              <AssigneeAvatar showModel={false} data={task?.assignedBy} />
            </Col>
          ) : null}
          <Col
            md={2}
            xl={view === "list" ? 2 : 12}
            lg={3}
            className={`HideBoard  ${view === "list" ? "o6" : "o4 mt-2"}`}
          >
            <Typography.Text ellipsis>
              {moment(task?.dueDate)?.format("DD MMM YYYY") ===
              "01 Jan 0001" ? (
                <LineOutlined />
              ) : (
                moment(task?.dueDate)?.format("DD MMM YYYY")
              )}
            </Typography.Text>
          </Col>

          <Col
            lg={3}
            xl={view === "list" ? 1 : 12}
            style={{ cursor: "pointer" }}
            className={`HideBoard ${view === "list" ? "o7" : "o5 mt-2 mb-1"}`}
          >
            <Tag
              style={{ width: 60, textAlign: "center" }}
              bordered={false}
              color={getPriorityColor(task?.priority)}
            >
              {priorityNameObj[task?.priority]}
            </Tag>
          </Col>
        </Row>
      );
    },
    () => false
  );
  const onDragEnd = useCallback(
    async (
      result: IDragEnd | undefined,
      task: any,
      deleteTask: boolean = false
    ): Promise<boolean> => {
      try {
        if (result?.success) {
          let res: any = undefined;
          const details = handleChange();
          if (deleteTask) {
            res = await deleteTaskAsync(result?.data?.taskId);
          } else {
            res = await mutateAsync({
              tid: result?.data?.taskId,
              tstatus: Number(result?.data?.taskStatus),
            });
          }

          if (!!res?.message) {
            message.error(`Error in update status => ${res?.message}`);
            throw new Error(res?.message);
          } else {
            const logPayload = {
              taskId: result?.data?.taskId,
              user: {
                id: user?.id,
                name: user?.name,
              },
              project: {
                id: details?.project?.id,
                name: details?.project?.name,
              },
              logs: [
                {
                  tid: result?.data?.taskId,
                  assigneeId: user?.id,
                  assigneeName: user?.name,
                  cngType: 2,
                  fieldName: "Status",
                  from: taskStatusNameObj[String(task?.tStatus)],
                  isComment: 2,
                  pId: details?.project?.id,
                  remarks: `${user?.name} Updated The Status`,
                  to: taskStatusNameObj[String(result?.data?.taskStatus)],
                  uKey: task?.uKey,
                },
              ],
            };
            const rt = await taskLogMutateAsync(logPayload);
            message.success(`Task status updated successfully`);
            return true;
          }
        } else throw new Error(`Error in DndTask module => ${result?.success}`);
      } catch (e) {
        message.error(`Error in board onDragEnd => ${e}`);
        console.error(`Error in board onDragEnd => ${e}`);
        return false;
      }
    },
    [taskSearchFilter]
  );

  const addEditTask = useCallback(() => {
    return (
      <>
        {!!selectedRecord && (
          <AddEditTask
            id={selectedRecord?.id}
            onDismiss={(rec: boolean) => {
              setSelectedRecord(undefined);
            }}
          />
        )}
      </>
    );
  }, [selectedRecord]);
  const projectTimeLine = useCallback(() => {
    return (
      <Suspense>
        <ProjectTimeline projectId={taskSearchFilter?.project?.id as string} />
      </Suspense>
    );
  }, [taskSearchFilter?.project?.id]);

  const TaskHeader = memo(
    () => {
      return (
        <div
          style={{
            backgroundColor: "#f2f6fe",
            position: "sticky",
            zIndex: 1,
            top: 0,
            margin: "0px 8px 0px 4px",
            borderRadius: 8,
          }}
        >
          <Row
            // style={listStyle}
            gutter={[0, 0]}
            align={"middle"}
            justify={"space-evenly"}
            className="w100"
            style={{
              ...listStyle,
              backgroundColor: "#f2f6fe",
              borderRadius: 8,
            }}
          >
            <Col lg={5} xl={1} md={5}>
              <Typography.Text ellipsis title="Ukey">
                Ukey
              </Typography.Text>
            </Col>

            <Col lg={5} md={5} xl={4}>
              <Typography.Text ellipsis title="Title">
                Title
              </Typography.Text>
            </Col>
            <Col lg={4} md={3} xl={3} className="HideBoard">
              <Typography.Text ellipsis>Status</Typography.Text>
            </Col>
            <Col lg={2} xl={2} md={3} className="HideBoard">
              <Typography.Text ellipsis>Assignee</Typography.Text>
            </Col>
            <Col lg={2} md={3} xl={1} className="HideBoard">
              <Typography.Text ellipsis>Issuer</Typography.Text>
            </Col>
            <Col md={2} xl={2} lg={3} className="HideBoard">
              <Typography.Text ellipsis>Due Date</Typography.Text>
            </Col>

            <Col lg={3} xl={1} className="HideBoard">
              <Typography.Text ellipsis>Priority</Typography.Text>
            </Col>
          </Row>
        </div>
      );
    },
    () => true
  );

  const values = Form.useWatch([], form);

  const handleChange = useCallback(() => {
    const val = form.getFieldsValue();
    let data: ITaskFilter & ITaskViewMode = {
      ...val,
      project: {
        id: val?.project?.value,
        name: val?.project?.label,
      },
      priority:
        val?.priority?.length > 0
          ? val?.priority?.map((i: any) => i?.value)
          : undefined,
      searchIn: val?.searchIn?.value,
      date: !!val?.date
        ? {
          from: moment(val?.date?.[0]?.format("DD MMM YYYY")),
          to: moment(val?.date?.[1]?.format("DD MMM YYYY")),
          }
        : undefined,
      teamMemberIds:
        val?.teamMemberIds?.length > 0
          ? val?.teamMemberIds?.map((i: any) => i?.value)
          : undefined,
    };
    delete data?.viewMode;
    setTaskSearchFilter({ ...data });
    return data;
  }, [form, values]);
  React.useEffect(() => {
    handleChange();
  }, [form, values]);
  const boardJsx = useCallback(() => {
    return (
      <Spin
        spinning={
          isUpdateTaskStatus || isTaskLogs || isFetchingTaskList || isDeleteTask
        }
        indicator={<LoadingOutlined spin />}
        size="default"
      >
        <Row gutter={[0, 0]} justify={"space-between"}>
          <Col
            md={24}
            lg={16}
            xl={17}
            className="board-rounded-card capTask-custom-col"
          >
            <TaskSearchFilter
              setLoading={setLoading}
              frm={form}
              subFilterStyle={taskSubFilterStyle}
              style={{ padding: "0px 10px 0px" }}
              onChange={(filters: ITaskFilter) => {
                setTaskSearchFilter({ ...filters });
              }}
              key={"BoardTaskSearchFilter"}
            />
            <div style={{ height: "75vh", overflow: "auto", borderRadius: 8 }}>
              <Suspense>
                <DndTask
                  isFetchingTaskList={
                    isUpdateTaskStatus ||
                    isTaskLogs ||
                    isFetchingTaskList ||
                    isDeleteTask
                  }
                  header={<TaskHeader />}
                  mode={boardView?.viewMode}
                  key={"BoardDndTask"}
                  primaryApiData={primaryApiData}
                  onDragEnd={async (result: IDragEnd | undefined, task) => {
                    return await onDragEnd(result, task);
                  }}
                >
                  {(record, cls) => <TaskView task={record} view={cls} />}
                </DndTask>
              </Suspense>
            </div>
          </Col>
          <Col
  
            md={24}
            lg={8}
            xl={6}
   
            style={{
              marginTop: "8px",
              backgroundColor: "white",
              padding: "14px",
              borderRadius: 12,
              height: "83vh",
              overflow: "auto",
            }}
          >
            {projectTimeLine()}
          </Col>
        </Row>
      </Spin>
    );
  }, [
    isUpdateTaskStatus,
    boardView?.viewMode,
    primaryApiData,
    isTaskLogs,
    taskSearchFilter?.project?.id,
  ]);

  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) {
    return <BoardBacklogFrameLoading breadcrumbItems={breadcrumbItems} />;
  }
  return (
    <>
      <div>
        <Row justify={"end"} className="mb-2 px-2">
          <Col style={{ fontSize: "12px", textAlign: "center" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
      </div>
      {boardJsx()}
      {addEditTask()}
      {!!deleteTaskData && (
        <DeleteTaskModel id={deleteTaskData?.id} ukey={deleteTaskData?.ukey} />
      )}
    </>
  );
};

export default memo(Board, () => false);
