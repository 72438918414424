import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Layout,
  Menu,
  MenuProps,
  Popover,
  Row,
  theme,
  Typography,
} from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  ProductOutlined,
  DesktopOutlined,
  ProjectOutlined,
  TeamOutlined,
  AuditOutlined,
  RedoOutlined,
  SettingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "suneditor/dist/css/suneditor.min.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../common/common.css";
import { AppRoutesPath } from "../Routes/AppRoutesPath";
import { useDispatch, useSelector } from "react-redux";
import { resetUserData, userData } from "../store/slices/user-slice";
import Changepassword from "./changepassword";
import UploadComponent from "./UploadAvatar";
import "./Changepassword.css";
import { resetProjectData } from "../store/slices/project-slice";
import { isUserAdmin, isUserTeamLeader } from "../utils/Util";
import AddEditTask from "../common/AddEditTask";
import "../common/common.css";
import { collapse, setCollapse } from "../store/slices/collapse-slice";
import ApplicationRoutes from "../Routes/ApplicationRoutes";
import { useAssigneeLastActivity } from "../Services/RQUserService";
import { dayjs } from "../utils/dayjs";
type MenuItem = Required<MenuProps>["items"][number];
const MainLayout = ({ children }: any) => {
  const isCollapse = useSelector(collapse);
  const params: any = useParams();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const key = params["*"]?.split("/").pop() || "";
  const user = useSelector(userData);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const openModalCreate = () => setIsOpenCreate(true);
  const closeModalCreate = () => setIsOpenCreate(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const location = useLocation();
  let url = location?.pathname?.substring(1)?.split("/")[0];
  const navigate = useNavigate();
  const loadData = async () => {
    try {
      console.log("Loading data...");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log("Data loaded!");
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  const items = useMemo(
    () => [
      {
        label: "My Work",
        key: AppRoutesPath.dashboard,
        icon: <ProductOutlined />,
        onClick: async () => {
          if (url !== AppRoutesPath.dashboard) {
            navigate(AppRoutesPath.dashboard);
          }
          await loadData();
        },
      },

      {
        label: "Board",
        key: AppRoutesPath.board,
        icon: <DesktopOutlined />,
        onClick: () => {
          if (url !== AppRoutesPath.board) navigate(AppRoutesPath.board);
        },
      },
      {
        label: "Backlog",

        key: AppRoutesPath.backLog,
        icon: <ProjectOutlined />,
        onClick: () => {
          if (url !== AppRoutesPath.backLog) navigate(AppRoutesPath.backLog);
        },
      },
      {
        label: "People",
        icon: <TeamOutlined />,
        key: AppRoutesPath.peoples,
        onClick: () => {
          if (url !== AppRoutesPath.peoples) navigate(AppRoutesPath.peoples);
        },
      },
      // {
      //   label: "Meetings",
      //   key: AppRoutesPath.meetings,
      //   icon: <ApartmentOutlined />,
      //   onClick: () => navigate(AppRoutesPath.meetings),
      //   disabled: true,
      // },
      // {
      //   label: "Milestone",
      //   key: AppRoutesPath.mileStone,
      //   icon: <FundOutlined />,
      //   onClick: () => navigate(AppRoutesPath.mileStone),
      //   disabled: true,
      // },
      {
        label: "Reports",
        key: AppRoutesPath.reports?.home,
        icon: <AuditOutlined />,
        onClick: () => {
          if (url !== AppRoutesPath?.reports?.home)
            navigate(AppRoutesPath?.reports?.home);
        },
      },
      isUserAdmin()
        ? {
            label: "Settings",
            key: AppRoutesPath?.setting?.home,
            icon: <SettingOutlined />,
            onClick: () => {
              if (url !== AppRoutesPath?.setting?.home) {
                navigate(AppRoutesPath?.setting?.home);
              }
            },
          }
        : null,
      isUserTeamLeader()
        ? {
            label: "Settings",
            key: AppRoutesPath?.setting?.home,
            icon: <SettingOutlined />,
            onClick: () => {
              if (url !== AppRoutesPath?.setting?.home) {
                navigate(AppRoutesPath?.setting?.home);
              }
            },
          }
        : null,
    ],
    [url]
  );

  const UserContent = () => {
    const user = useSelector(userData);
    return (
      <>
        <div style={{ width: 275 }}>
          <Row>
            <Col span={5}>
              <Avatar
                size={40}
                src={user?.imageThumb}
                onClick={() => {
                  openUpload();
                  setShowPopup(!showPopup);
                }}
                style={{ cursor: "pointer" }}
              />
            </Col>
            <Col span={19}>
              <Col
                span={24}
                style={{
                  textAlign: "left",
                }}
              >
                <Typography.Text strong ellipsis style={{ fontSize: "14px" }}>
                  {user?.name}
                </Typography.Text>
              </Col>
              <Col
                style={{
                  textAlign: "left",
                }}
                span={24}
              >
                <Typography.Text ellipsis style={{ fontSize: "13px" }}>
                  {user?.email}
                </Typography.Text>
              </Col>
            </Col>
          </Row>
          <Row className="border-bottom mt-2" gutter={[24, 24]}></Row>

          <Row gutter={[24, 16]} className="py-2">
            <Col>
              <Link
                style={{
                  fontSize: 16,
                  fontFamily: "Open Sans",
                  cursor: "pointer",
                  color: "rgb(37, 49, 72)",
                }}
                to="/profile"
              >
                <Button
                  type="text"
                  icon={<UserOutlined />}
                  style={{ color: "#377dff", fontSize: 15 }}
                  disabled
                  onClick={() => {
                    setShowPopup(!showPopup);
                  }}
                >
                  Profile
                </Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={[24, 16]} className="py-2">
            <Col>
              <Button
                type="text"
                icon={<RedoOutlined />}
                onClick={() => {
                  setIsOpen(true);
                  setShowPopup(!showPopup);
                }}
                style={{ color: "#377dff", fontSize: 15 }}
              >
                Change password
              </Button>
            </Col>
          </Row>
          <Row className="border-bottom mt-4" gutter={[24, 16]}></Row>
          <Row gutter={[24, 16]} className="py-2">
            <Col>
              <Button
                type="text"
                icon={<LogoutOutlined />}
                onClick={() => {
                  handleSignOut();
                }}
                style={{ color: "rgb(253, 136, 158", fontSize: 15 }}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const handleSignOut = useCallback(() => {
    dispatch(resetUserData());
    dispatch(resetProjectData());
  }, []);
  const setbacklogrefresh = (prev: any) => {
    return prev + 1;
  };
  const openModal = () => setIsOpen(true);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openUpload = () => setIsUpload(true);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const closeUpload = () => setIsUpload(false);
  const defaultKey = localStorage.getItem("currentMenuKey") || "";
  const [current, setCurrent] = useState(defaultKey);

  const onClick = (e: any) => {
    setCurrent(e.key);
    localStorage.setItem("currentMenuKey", e?.key);
  };
  useEffect(() => {
    setCurrent(defaultKey);
  }, [defaultKey]);

  const { Header, Content, Sider } = Layout;
  type MenuItem = Required<MenuProps>["items"][number];

  const setCollapseValue = useCallback((value: boolean) => {
    dispatch(setCollapse({ collapse: value }));
  }, []);
  const [collapsed, setCollapsed] = useState(false);
  const handleResize = () => {
    if (window.innerWidth <= 1500 && window.innerWidth >= 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const CollapseLogo = memo(
    () => {
      return (
        <div
          className={isCollapse ? "collSideLogo" : "sideLogo"}
          onClick={() => setCollapseValue(!isCollapse)}
        >
          {isCollapse ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="32"
                viewBox="0 0 36 32"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.63542 11.1384C0.593426 12.092 0 13.4394 0 14.8519V26.6068C0 29.3869 2.25367 31.6405 5.03371 31.6405H17.9775C20.7576 31.6405 23.0113 29.3869 23.0113 26.6068V11.1461C23.0113 8.3661 20.7576 6.11243 17.9775 6.11243H9.08315C7.82522 6.11243 6.61286 6.58343 5.68486 7.43267L1.63542 11.1384Z"
                  fill="#3AC4FF"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.80247 12.6787C5.85846 13.6227 5.32812 14.903 5.32812 16.2381V26.5605C5.32812 29.3405 7.58179 31.5942 10.3618 31.5942H30.2021C32.9821 31.5942 35.2358 29.3405 35.2358 26.5605V5.03371C35.2358 2.25367 32.9821 0 30.2021 0H21.5662C20.2312 0 18.9508 0.530336 18.0068 1.47434L6.80247 12.6787Z"
                  fill="#1162FF"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M35.2361 10.7229L24.8569 21.1385L19.6332 15.8964C18.7221 14.9821 17.2423 14.9795 16.328 15.8906C15.4138 16.8017 15.4112 18.2815 16.3222 19.1958L24.8569 27.7604L35.2361 17.3448V10.7229Z"
                  fill="white"
                />
              </svg>
            </>
          ) : (
            <img
              style={{ height: 52, cursor: "pointer" }}
              src={process.env.PUBLIC_URL + "/CapsiTask.png"}
              alt="Logo"
            />
          )}
        </div>
      );
    },
    () => true
  );
  const MenuMemo: React.FC<{ path: string }> = memo(
    ({ path }) => {
      return (
        <Menu
          className="sidebar"
          theme="light"
          defaultSelectedKeys={[path]}
          selectedKeys={[path]}
          mode="inline"
          items={items}
        />
      );
    },
    (p, n) => p.path === n.path
  );

  const { data: LastActivity } = useAssigneeLastActivity(user?.id);
  const handleInactivityCheck = () => {
    if (LastActivity?.result?.createdBy?.date) {
      const activityDate = dayjs(LastActivity?.result?.createdBy?.date);
      const daysInactive = dayjs().diff(activityDate, "day");
      const daysThreshold = 7;
      if (daysInactive >= daysThreshold) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme="light"
          collapsed={isCollapse}
          onCollapse={(value) => setCollapseValue(value)}
          collapsedWidth={50}
          style={{ transition: "0.4s" }}
        >
          <CollapseLogo />
          <MenuMemo path={url} />
        </Sider>
        <Layout style={{ transition: "0.4s" }}>
          <Header style={{ padding: 0, background: colorBgContainer }}>
            <Row gutter={[0, 16]} justify={"space-between"}>
              <Col span={10}>
                <Typography.Text className="ml-6" style={{ color: "#7b8ca8" }}>
                  Welcome, &nbsp;{user?.name}
                </Typography.Text>
              </Col>
              <Col>
                {location?.pathname !== "*" && (
                  <Button
                    icon={<PlusOutlined style={{ fontSize: "medium" }} />}
                    size="small"
                    style={{
                      fontWeight: 500,
                      padding: "2px 12px",
                      background: "#f3f4fa",
                      borderColor: "#d3e3ff",
                      color: "#377dff",
                    }}
                    onClick={openModalCreate}
                    disabled={handleInactivityCheck() && !isUserAdmin()}
                  >
                    Create
                  </Button>
                )}
                <Popover
                  content={<UserContent />}
                  title=""
                  trigger="click"
                  onOpenChange={setShowPopup}
                  open={showPopup}
                >
                  <Avatar
                    className="userAvatar"
                    src={user?.imageThumb}
                    size={30}
                    // icon={<UserOutlined />}
                    onClick={() => setShowPopup(!showPopup)}
                  />
                </Popover>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: "0px",
              height: "90vh",
              overflowY: "auto",
              background: "#f3f4fa",
              padding: "10px 18px",
              transition: "0.4s",
            }}
          >
            <ApplicationRoutes />
          </Content>
        </Layout>
      </Layout>
      {isOpen && (
        <Changepassword
          openModal={openModal}
          closeModal={closeModal}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      {isUpload && (
        <UploadComponent
          isUpload={isUpload}
          closeUpload={closeUpload}
          setIsUpload={setIsUpload}
        />
      )}
      {isOpenCreate && (
        <AddEditTask
          onDismiss={() => {
            closeModalCreate();
            setIsOpenCreate(false);
            setbacklogrefresh((prev: any) => prev + 1);
          }}
        />
      )}
    </>
  );
};

export default MainLayout;
