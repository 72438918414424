import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ITaskViewMode } from "../../components/dnd/TaskSearchFilter";

type IPinUsersInitialState = {
  users:{label:any,value:any}[]
};

const initialState:IPinUsersInitialState = {
  users:[]
}

const pinSlice = createSlice({
  name: "pinUsers",
  initialState,
  reducers: {
    setPinUsers: (state, action: PayloadAction<IPinUsersInitialState>) => {
        state.users = action.payload.users;
    },
  },
});

export const { setPinUsers } = pinSlice.actions;
export const getPinUsers = (state: RootState): {label:any,value:any}[] => state.pin.users

export default pinSlice.reducer;
