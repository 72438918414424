import { useCallback } from "react";
import { queryClient } from "..";
import HTTPSCalls from "../Services/HTTPCalls";
import { RootState, store } from "../store";
import { message } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { dayjs } from "./dayjs";
import { IApiResponse } from "../Services/ApiUtility";
import moment from "moment";

enum ActivityType {
  Log = 0,
  Task = 1,
}

type Priority = 0 | 1 | 2;
export let globalobject = {
  companyid: "",
};

export const substringmethod = (data: string) => {
  if (data === null || data === undefined) return "";

  if (data.length > 30) {
    return data.substring(0, 30) + "...";
  } else {
    return data;
  }
};
export const substringmethodname = (data: string) => {
  if (data === null || data === undefined) return "";

  if (data.length > 10) {
    return data.substring(0, 10) + "...";
  } else {
    return data;
  }
};
export const substringListViewModal = (data: string) => {
  if (data === null || data === undefined) return "";

  if (data.length > 60) {
    return data.substring(0, 60) + "...";
  } else {
    return data;
  }
};

export const textnumbergenerator = (name: string) => {
  if (name) return name.charCodeAt(0) % 7;
  else return 0;
};

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function ActivityTypeToText(activity: ActivityType) {
  switch (activity) {
    case ActivityType.Log:
      return "Logs";
    case ActivityType.Task:
      return "Tasks";
  }
}

export const FindDayDifferenceInDates = (from: string, to: string) => {
  const fromdateobject: any = new Date(from);
  const todateobject: any = new Date(to);
  const diffTime = Math.abs(todateobject - fromdateobject);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const initialsgenerator = (name: string) => {
  if (name) {
    let arr = name?.split(" ");
    if (arr.length > 1) {
      return (arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase();
    } else {
      return (arr[0].charAt(0) + arr[0].charAt(1)).toUpperCase();
    }
  } else {
    return "NA";
  }
};

export const prioritycolorchange = (priority: number) => {
  if (priority === 2) {
    return "#ff9292";
  } else if (priority === 1) {
    return "#ffc69a";
  } else {
    return "#d1d1d1";
  }
};

export const Priorityflag = (priority: number) => {
  switch (priority) {
    case 2:
      return "red";
    case 1:
      return "orange";
    case 0:
      return "yellow";
  }
};

export function compareObjects(obj1: any, obj2: any) {
  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys in both objects is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare values
  for (const key of keys1) {
    // Check if the key exists in both objects
    if (!keys2.includes(key)) {
      return false;
    }

    // Compare the values of the corresponding keys
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If all keys and values match, return true
  return true;
}

// const imagePath2 = (imageName: string) => {
//   let imageNames = imageName.split(".");

//   if (imageNames[imageNames.length - 1] === "pdf") {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/pdf.svg"}
//         alt=""
//       />
//     );
//   } else if (imageNames[imageNames.length - 1] === "png") {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/png.svg"}
//         alt=""
//       />
//     );
//   } else if (imageNames[imageNames.length - 1] === "doc") {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/doc.svg"}
//         alt=""
//       />
//     );
//   } else if (imageNames[imageNames.length - 1] === "xlxs") {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/xlxs.svg"}
//         alt=""
//       />
//     );
//   } else if (imageNames[imageNames.length - 1] === "xls") {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/xls.svg"}
//         alt=""
//       />
//     );
//   } else if (imageNames[imageNames.length - 1] === "jpg") {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/jpg.svg"}
//         alt=""
//       />
//     );
//   } else {
//     return (
//       <img
//         style={{ paddingTop: "2px", height: "40px" }}
//         src={process.env.PUBLIC_URL + "/other.svg"}
//         alt=""
//       />
//     );
//   }
// };


export const priorityconvertor = (priority: any) => {
  let extenshionObject: any = {
    0: "Low",
    1: "Medium",
    2: "High",
  };
  return extenshionObject[priority] || "none";
};

//date
export function formatDate(value: string): string {
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export const selectvalue = [
  { label: "Backlog", value: 0 },
  { label: "Todo", value: 1 },
  { label: "Need To Discuss", value: 2 },
  { label: "In Progress", value: 3 },
  { label: "UAT", value: 4 },
  { label: "Developed", value: 7 },
  { label: "Duplicate", value: 8 },
  { label: "Testing", value: 5 },
  { label: "Done", value: 6 },
];

export const statusconvertor = (status: number) => {
  let extenshionObject: any = {
    0: "Backlog",
    1: "Todo",
    2: "Need to Discuss",
    3: "In Progress",
    4: "UAT",
    5: "Testing",
    6: "Done",
    7: "Developed",
  };
  return extenshionObject[status] || "None";
};

export const statusvalueconvertor = (status: string) => {
  let extenshionObject: any = {
    Backlog: 0,
    Todo: 1,
    "Need to Discuss": 2,
    "In Progress": 3,
    UAT: 4,
    Testing: 5,
    Done: 6,
    Developed: 7,
  };
  return extenshionObject[status] || "None";
};

export const droppableconvertor = (status: string) => {
  let extenshionObject: any = {
    Todo: 0,
    NeedToDiscuss: 1,
    InProgress: 2,
    UAT: 3,
    Testing: 4,
    Done: 5,
    Developed: 6,
  };
  return extenshionObject[status] || "None";
};

export const statusbackgroundgenerator = (status: any) => {
  if (status?.toLowerCase()?.trim() === "todo") {
    return "#eaeeff";
  } else if (status?.toLowerCase()?.trim() === "need to discuss") {
    return "#d6fef4";
  } else if (status?.toLowerCase()?.trim() === "in progress") {
    return "#fcefe6";
  } else if (status?.toLowerCase()?.trim() === "developed") {
    return "#ffeafb";
  } else if (status?.toLowerCase()?.trim() === "uat") {
    return "#ebe7ff";
  } else if (status?.toLowerCase()?.trim() === "testing") {
    return "#ffeff3";
  } else if (status?.toLowerCase()?.trim() === "done") {
    return "#e8faed";
  } else if (status?.toLowerCase()?.trim() === "backlog") {
    return "#fff9e8";
  } else if (status?.toLowerCase()?.trim() === "Developed") {
    return "";
  } else {
    return "";
  }
};

export const statusbackgroundfont = (status: any) => {
  if (
    status === "NeedToDiscuss" ||
    status === "NeedtoDiscuss" ||
    status === "NeedToDiscuss"
  ) {
    status = "need to discuss";
  } else if (status === "InProgress") {
    status = "in progress";
  }

  if (status?.toLowerCase().trim() === "todo" || status === 1) {
    return "#eaeeff";
  } else if (
    status?.toLowerCase().trim() === "need to discuss" ||
    status === 2
  ) {
    return "#d6fef4";
  } else if (status?.toLowerCase().trim() === "in progress" || status === 3) {
    return "#fcefe6";
  } else if (status?.toLowerCase().trim() === "developed" || status === 7) {
    return "#ffeafb";
  } else if (status?.toLowerCase().trim() === "uat" || status === 4) {
    return "#ebe7ff";
  } else if (status?.toLowerCase().trim() === "testing" || status === 5) {
    return "#ffeff3";
  } else if (status?.toLowerCase().trim() === "done" || status === 6) {
    return "#e8faed";
  } else if (status?.toLowerCase().trim() === "backlog" || status === 0) {
    return "#fff9e8";
  } else if (status?.toLowerCase().trim() === "Developed") {
    return "";
  } else {
    return "";
  }
};

export const statuscolorfont = (status: any) => {
  if (
    status === "NeedToDiscuss" ||
    status === "NeedtoDiscuss" ||
    status === "NeedToDiscuss"
  ) {
    status = "need to discuss";
  } else if (status === "InProgress") {
    status = "in progress";
  }

  if (status?.toLowerCase().trim() === "todo" || status === 1) {
    return "#0066C6";
  } else if (
    status?.toLowerCase().trim() === "need to discuss" ||
    status === 2
  ) {
    return "#50a18f";
  } else if (status?.toLowerCase().trim() === "in progress" || status === 3) {
    return "#e16a14";
  } else if (status?.toLowerCase().trim() === "developed" || status === 7) {
    return "#ff76af";
  } else if (status?.toLowerCase().trim() === "uat" || status === 4) {
    return "#4b5264";
  } else if (status?.toLowerCase().trim() === "testing" || status === 5) {
    return "#f28593";
  } else if (status?.toLowerCase().trim() === "done" || status === 6) {
    return "#3ab15b";
  } else if (status?.toLowerCase().trim() === "backlog" || status === 0) {
    return "#d78723";
  } else if (status?.toLowerCase().trim() === "Developed") {
    return "";
  } else {
    return "";
  }
};

export const prioritycolorfont = (status: string) => {
  // Convert status to lowercase and trim spaces for consistency
  const normalizedStatus = status?.toLowerCase().trim();

  if (normalizedStatus === "medium") {
    return "#50a18f"; // Color for Medium
  } else if (normalizedStatus === "high") {
    return "#e16a14"; // Color for High
  } else if (normalizedStatus === "low") {
    return "#0066C6"; // Color for Low
  } else {
    return ""; // Default case if status doesn't match any of the three
  }
};

export const removefromarray = (arr: any) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === 5) {
      arr.splice(i, 1);
    }
  }
};
export const randomhax = (index: number) => {
  const colorList = [
    "#247dff", "#43b4fa", "#d645d1", "#12c912", "#256be8",
    "#ff3399", "#af19d4", "#d12a2a", "#ffa500", "#0ddbc7",
    "#ff5733", "#33ff57", "#ff33a8", "#a833ff", "#3385ff",
    "#ff8633", "#33ff86", "#86ff33", "#ff3386", "#a8ff33",
    "#33a8ff", "#57ff33", "#ff5733", "#33ff57", "#33a8a8",
    "#a8a833", "#a83357", "#5733a8", "#85ff85", "#ff85a8",
    "#85a8ff", "#a8ff85", "#d4a6ff", "#ffc800", "#008cff",
    "#00ff8c", "#ff008c", "#8cff00", "#ff6600", "#6600ff",
    "#ff0066", "#0066ff", "#66ff00", "#006600", "#ff6600",
    "#66ffff", "#ffff66", "#ff6666", "#66ff66", "#6666ff",
    "#ff3399", "#3399ff", "#99ff33", "#9933ff", "#ff9933",
    "#33ff99", "#9933ff", "#ccff00", "#00ccff", "#ff00cc"
  ];

  return colorList[index % colorList.length];
};


export const getEllipsisText = (
  str: string,
  end: number = 20,
  start: number = 0
) => {
  if (str && str?.length > end) return str?.substring(start, end) + "...";
  else return str;
};

export const getAvatarUrl = (assignee: any) => {
  if (assignee?.thumb) {
    return assignee?.thumb;
  }
  if (assignee?.Thumb) {
    return assignee?.Thumb;
  }
  if (assignee?.imageThumbPath) {
    return assignee?.imageThumbPath;
  }

  if (assignee?.profileImage) {
    return assignee?.profileImage;
  }

  if (!assignee?.imageThumb) {
    const initials = initialsgenerator(assignee?.name || assignee?.Name);
    const number = textnumbergenerator(assignee?.name || assignee?.Name);
    return `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initials}-${number}.png?ssl=1`;
  }
  return assignee?.imageThumb;
};

export const isUserAdmin = () => {
  let result = false;
  const state = store.getState();
  if (state?.user?.user?.role?.toLocaleLowerCase() === "admin") {
    result = true;
  }
  return result;
};
export const isUserTeamLeader = () => {
  let result = false;
  const state = store.getState();
  if (state?.user?.user?.role?.toLocaleLowerCase() === "teamleader") {
    result = true;
  }
  return result;
};


export const newStatusConvertor = (status: string): number => {
  const extensionObject: { [key: string]: number } = {
    BACKLOG: 0,
    TODO: 1,
    NEEDTODISCUSS: 2,
    INPROGRESS: 3,
    UAT: 4,
    TESTING: 5,
    DONE: 6,
    DEVELOPED: 7,
  };
  return extensionObject[status.toUpperCase()] ?? -1;
};

export const addStatusChangeLog = async (
  task: any,
  to: any,
  from: any,
  cngType: number = 2,
  project: any = undefined
) => {
  const res = await addLog(
    task,
    statusconvertor(to),
    statusconvertor(from),
    cngType,
    project
  );
  if (res?.status) {
    queryClient.invalidateQueries({ queryKey: ["logsList"] });
    queryClient.invalidateQueries({
      queryKey: ["logsListDashboard"],
    });
  }
  return res;
};

export const addSprintChangeLog = async (task: any, to: any, from: any) => {
  const res = await addLog(task, to, from, 3);
  if (res?.status) {
    queryClient.invalidateQueries({ queryKey: ["logsList"] });
  }
  return res;
};

export const iconPriority = (priority: Priority): React.ReactNode => {
  let color: string;
  switch (priority) {
    case 2:
      color = "#ff8585";
      return (
        <span style={{ color }}>
          <TagOutlined
            className="Moveicon"
            // style={{ margin: "6px 21px" }}
          />
        </span>
      );
    case 1:
      color = "#ffd19e";
      return (
        <span style={{ color }}>
          <TagOutlined
            className="Moveicon"
            // style={{ margin: "6px 21px" }}
          />
        </span>
      );
    case 0:
      color = "#d9d9d9";
      return (
        <span style={{ color }}>
          <TagOutlined
            className="Moveicon"
            // style={{ margin: "6px 21px" }}
          />
        </span>
      );
    default:
      return null;
  }
};

export const addLog = async (
  task: any,
  to: any,
  from: any,
  cngType: Number = 3,
  extra?: any
) => {
  const currentState: RootState = store.getState();
  const requestBody = {
    project: {
      id: currentState?.project?.project?.id,
      name: currentState?.project?.project?.name,
    },
    taskId: task?._id,
    user: {
      id: currentState?.user?.user?.id,
      name: currentState?.user?.user?.name,
    },
    logs: [
      {
        assigneeId: currentState?.user?.user?.id,
        assigneeName: currentState?.user?.user?.name,
        cngType: cngType,
        email: currentState?.user?.user?.email, // user.email,
        from: from,
        isComment: 2,
        PId: currentState?.project?.project?.id,
        remarks: currentState?.user?.user?.name + " Updated The status ",
        tId: task?._id,
        to: to,
        uKey: task?.UKey,
      },
    ],
    ...extra,
  };
  const res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add, requestBody);
  if (res?.status) {
    queryClient.invalidateQueries({ queryKey: ["logsList"] });
  }
  return res;
};

export const getPostFormatParent = async (
  data: any,
  myDescription: any,
  projectIDs: any,
  user: any,
  parentTaskId: any = null
) => {
  try {
    const response = {
      ...data,
      taskId: parentTaskId ? parentTaskId : null,
      tStatus: 0,
      description: myDescription,
      projectIds: [projectIDs === "" ? data?.projectIds?.value : projectIDs],
      assignedByIds: [user?.id],
      reportersIds: [data?.reportersIds?.value],
      priority: data?.priority?.value,
      labelIds: data?.labelIds?.map((itm: any) => itm?.value),
      assigneeIds: data?.assigneeIds?.map((itm: any) => itm?.value),
      sprintIds: data?.sprintIds?.map((itm: any) => itm?.value),
      categoryIds: data?.categoryIds?.map((itm: any) => itm?.value),
      branch: data?.branch?.map((itm: any) => ({
        id: itm?.value,
        name: itm?.label,
      })),
      file: data?.file?.map((itm: any) => itm?.convertedFile),
    };
    return response;
  } catch (e) {
    message.error("Error in add parent task => " + e);
    console.error("Error in add parent task => ", e);
  }
};
export const getPostFormatChild = async (child: any) => {
  try {
    const response = child?.childTasks?.map((item: any) => ({
      title: item?.title,
      attachments: item?.attachments?.map((item: any) => ({
        name: item?.file?.name,
        contentType: item?.file?.type,
        path: item?.data_url,
        ExistingType: 1,
      })),
    }));

    return response;
  } catch (e) {
    message.error("Error in add child task => " + e);
    console.error("Error in add child task => ", e);
  }
};

export const getInLabelValueFormat = async (data: any) => {
  try {
    const response = data?.map((itm: any) => ({
      label: itm?.name,
      value: itm?.id,
    }));
    return response;
  } catch (e) {
    message.error("Error in getInLabelValueFormat => " + e);
    console.error("Error in getInLabelValueFormat => ", e);
  }
};

const getTasKInFormFormat = (data: any) => {
  try {
    const response = {
      ...data,
      assigneeIds: data?.assignee?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      branch: data?.branch?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      dueDate:
        moment(data?.dueDate)?.format("DD MMM YYYY") === "01 Jan 0001"
          ? null
          : dayjs(data?.dueDate),
      labelIds: data?.label?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      reportersIds: data?.reporter?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      sprintIds: data?.sprint?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      categoryIds: data?.category?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      projectIds: data?.project?.map((itm: any) => ({
        label: itm?.name,
        value: itm?.id,
      })),
      file: data?.file?.map((itm: any) => ({
        ...itm,
        thumbUrl: itm?.path,
      })),
    };
    return response;
  } catch (e) {
    message.error("Error in getTasKInFormFormat => " + e);
    console.error("Error in getTasKInFormFormat => ", e);
  }
};

export const getTaskInFormFormatData = (data: any) => {
  try {
    const response = {
      ...getTasKInFormFormat(data),
      childTasks: data?.childTasks?.map((itm: any) => ({
        ...getTasKInFormFormat(itm),
      })),
    };
    sessionStorage.setItem(
      "taskDetail",
      JSON.stringify(response, (k, v) => (v === undefined ? "undefined" : v))
    );
    return response;
  } catch (e) {
    message.error("Error in getTaskInFormFormatData => " + e);
    console.error("Error in getTaskInFormFormatData => ", e);
  }
};

const includeFieldsToConvertIntoArray = [
  "projectIds",
  "reportersIds",
  "sprintIds",
  "categoryIds",
  "labelIds",
];

const convertTaskPostFormat = (data: any, user: any) => {
  try {
    if (data) {
      if (data?.isSetParentData) {
        const response = {
          ...data,
        };
        return response;
      } else {
        Object.keys(data)?.map((key: string) => {
          if (includeFieldsToConvertIntoArray.find((x: string) => x === key)) {
            if (data[key] && !Array.isArray(data[key])) {
              data[key] = Array(data[key]);
            }
          }
        });
        const response = {
          ...data,
          projectIds: data?.projectIds?.map((itm: any) => itm?.value),
          assignedByIds: user?.id ? [user?.id] : undefined,
          reportersIds: data?.reportersIds?.map((itm: any) => itm?.value),
          labelIds: data?.labelIds?.map((itm: any) => itm?.value),
          assigneeIds: data?.assigneeIds?.map((itm: any) => itm?.value),
          sprintIds: data?.sprintIds?.map((itm: any) => itm?.value),
          categoryIds: data?.categoryIds?.map((itm: any) => itm?.value),
          branch: data?.branch?.map((itm: any) => ({
            id: itm?.value,
            name: itm?.label,
          })),
          generateLogs: true,
          loginUser: { ...user },
        };
        return response;
      }
    }
    return data;
  } catch (e) {
    message.error(`Error in convertTaskPostFormat => ${e}`);
    console.error(`Error in convertTaskPostFormat => ${e}`);
  }
};

export const getTaskPostFormatData = async (
  parentData: any,
  user: any,
  childData: any,
  taskMode: ChildTaskCreationMethod
) => {
  try {
    const response = {
      parent: {
        ...convertTaskPostFormat(parentData, user),
        dueDate: !!parentData?.dueDate ? parentData?.dueDate : undefined,
      },
      childTasks: childData?.map((task: any) => ({
        ...convertTaskPostFormat(task, user),
        dueDate: !!parentData?.dueDate ? parentData?.dueDate : undefined,
      })),
      mode: taskMode,
    };
    return response;
  } catch (e) {
    message.error(`Error in getTaskPostFormatData => ${e}`);
    console.error(`Error in getTaskPostFormatData => ${e}`);
  }
};

export enum ChildTaskCreationMethod {
  Undefined,
  Standard,
  Drawer,
}
const convertToArrayField = (data: any) => {
  try {
    if (data) {
      Object.keys(data)?.map((key: string) => {
        if (includeFieldsToConvertIntoArray.find((x: string) => x === key)) {
          if (data[key] === "undefined") data[key] = undefined;

          if (data[key] && !Array.isArray(data[key])) {
            data[key] = Array(data[key]);
          }
        }
      });
      const response = {
        ...data,
      };
      return response;
    }

    return data;
  } catch (e) {
    message.error(`Error in convertToArrayField => ${e}`);
    console.error(`Error in convertToArrayField => ${e}`);
  }
};
const includeFields = [
  "title",
  "priority",
  "sprintIds",
  "categoryIds",
  "labelIds",
  "branch",
  "reportersIds",
  "assigneeIds",
  "dueDate",
  "tStatus",
  "description",
];
const LogFieldNameObj: any = {
  title: "Title",
  priority: "Priority",
  sprintIds: "Sprint",
  categoryIds: "Category",
  labelIds: "Issue Type",
  branch: "Branch",
  reportersIds: "Reporter",
  assigneeIds: "Assignee",
  dueDate: "Due Date",
  tStatus: "Status",
  description: "Description",
};
const isPrimitive = (data: any) =>
  typeof data === "bigint" ||
  typeof data === "boolean" ||
  typeof data === "number" ||
  typeof data === "string" ||
  typeof data === "undefined" ||
  data === null;
const isNotEqual = (a: any, b: any) => a !== b;

const getNames = (value: any): string => {
  let name: string = "";
  value?.forEach((itm: any) => {
    if (!!!name) name += itm?.label;
    else name += "," + itm?.label;
  });
  return name;
};
export const priorityNameObj: any = {
  "0": "Low",
  "1": "Medium",
  "2": "High",
};
export const taskStatusNameObj: any = {
  "0": "Backlog",
  "1": "Todo",
  "2": "Need to Discuss",
  "3": "In Progress",
  "4": "UAT",
  "5": "Testing",
  "6": "Done",
  "7": "Developed",
  "9": "Delete",
};
export const getLogs = (
  otherData: any = {},
  oldData: any,
  newData: any,
  logs: any[]
) => {
  debugger;
  const previousData = convertToArrayField(oldData);
  const updatedData = convertToArrayField(newData);
  for (let key in previousData) {
    if (includeFields.find((x: string) => x === key)) {
      let value1 = previousData?.[key];
      let value2 = updatedData?.[key];

      if (value1 === "undefined") value1 = undefined;
      if (value2 === "undefined") value2 = undefined;

      if (isPrimitive(value1) && isPrimitive(value2)) {
        if (isNotEqual(value1, value2)) {
          let fromValue: any = value1;
          let toValue: any = value2;
          if (key === "priority") {
            fromValue = priorityNameObj[value1 as string];
            toValue = priorityNameObj[value2 as string];
          }
          if (key === "tStatus") {
            fromValue = taskStatusNameObj[value1 as string];
            toValue = taskStatusNameObj[value2 as string];
          }
          logs.push({
            ...otherData,
            fieldName: LogFieldNameObj[key],
            from: fromValue,
            to: toValue,
            remarks: `${otherData?.remarks}${LogFieldNameObj[key]}`,
          });
        }
      } else if (Array.isArray(value1) || Array.isArray(value2)) {
        let removed = value1?.map((v1: any) => {
          if (value2?.find((x: any) => x?.value === v1?.value)) {
            return undefined;
          } else return { ...v1, flag: "removed" };
        });
        let added = value2?.map((v1: any) => {
          if (value1?.find((x: any) => x?.value === v1?.value)) {
            return undefined;
          } else return { ...v1, flag: "added" };
        });

        if (Array.isArray(removed) || Array.isArray(added)) {
          let r = removed?.filter((x: any) => !!x);
          let a = added?.filter((x: any) => !!x);
          if (
            Array.isArray(r) &&
            r?.length > 0 &&
            Array.isArray(a) &&
            a?.length > 0
          ) {
            logs.push({
              ...otherData,
              fieldName: LogFieldNameObj[key],
              from: getNames(r),
              to: getNames(a),
              remarks: `${otherData?.assigneeName} removed and added ${LogFieldNameObj[key]}`,
              formatting: {
                from: {
                  flag: "remove",
                },
                to: {
                  flag: "added",
                },
              },
            });
          } else if (Array.isArray(r) && r?.length > 0) {
            logs.push({
              ...otherData,
              fieldName: LogFieldNameObj[key],
              from: getNames(value1),
              to: getNames(r),
              remarks: `${otherData?.assigneeName} removed ${LogFieldNameObj[key]}`,
              formatting: {
                from: {
                  flag: "none",
                },
                to: {
                  flag: "remove",
                },
              },
            });
          } else if (Array.isArray(a) && a?.length > 0) {
            logs.push({
              ...otherData,
              fieldName: LogFieldNameObj[key],
              from: getNames(value1),
              to: getNames(a),
              remarks: `${otherData?.assigneeName} added ${LogFieldNameObj[key]}`,
              formatting: {
                from: {
                  flag: "none",
                },
                to: {
                  flag: "added",
                },
              },
            });
          }
        }
      } else if (dayjs(value1)?.isValid() || dayjs(value2)?.isValid()) {
        if (!!value1 && !!!value2) {
          logs.push({
            ...otherData,
            fieldName: LogFieldNameObj[key],
            from: dayjs(value1)?.format("DD MMM YYYY"),
            to: value2,
            remarks: `${otherData?.remarks}${LogFieldNameObj[key]}`,
          });
        } else if (!!!value1 && !!value2) {
          logs.push({
            ...otherData,
            fieldName: LogFieldNameObj[key],
            from: value1,
            to: dayjs(value2)?.format("DD MMM YYYY"),
            remarks: `${otherData?.remarks}${LogFieldNameObj[key]}`,
          });
        } else {
          if (
            isNotEqual(
              dayjs(value1)?.format("DD MMM YYYY"),
              dayjs(value2)?.format("DD MMM YYYY")
            )
          )
            logs.push({
              ...otherData,
              fieldName: LogFieldNameObj[key],
              from: dayjs(value1)?.format("DD MMM YYYY"),
              to: dayjs(value2)?.format("DD MMM YYYY"),
              remarks: `${otherData?.remarks}${LogFieldNameObj[key]}`,
            });
        }
      }
    }
  }
};
export const getPriorityColor = (priority: number | string): string => {
  let color: string = "";
  if (String(priority) === "0") color = "error";
  if (String(priority) === "1") color = "processing";
  if (String(priority) === "2") color = "success";
  return color;
};

export const priorityOptions = [
  { label: "Low", value: 0 },
  { label: "Medium", value: 1 },
  { label: "High", value: 2 },
];
