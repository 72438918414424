import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";

export const useLogList = (payload: any) => {
  return useQuery({
    queryKey: ["getLogList", payload],
    queryFn: () => getLogList(payload),
  });
};

export const useInfiniteLogList = (payload: any,getAll:boolean=false) => {
  return useInfiniteQuery({
    enabled:getAll?true:!!payload?.projectId,
    initialPageParam: 0,
    queryKey: ["getInfiniteLogList", payload],
    queryFn: ({ pageParam }) =>
      getLogList({
        ...payload,
        start: pageParam * payload?.length,
      }),
    getNextPageParam: (lastPage, allPage) => {
      if (
        lastPage?.result?.totalRecords <= 0 ||
        lastPage?.message ||
        (lastPage?.errors?.length || 0) >= 1
      )
        return undefined;

      if (allPage?.length < lastPage?.result?.totalRecords / payload?.length)
        return allPage?.length;
      else return undefined;
    },
  });
};

export const useAddComment = () => {
  return useMutation({
    mutationFn: async (payload: any) => {
      return await addComment(payload);
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({
          queryKey: ["getLogList"],
        });
      }
    },
  });
};
export const useSaveTaskLog = (
  queryKey: "getLogList" | "getInfiniteLogList"|"AssigneeLastActivity" = "getLogList" 
) => {
  return useMutation({
    mutationFn: async (payload: any) => {
      return await addTaskLog(payload);
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({
          queryKey: [queryKey],
        });
      }
    },
  });
};

const addTaskLog = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add, {
    ...payload,
  });

const addComment = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add, {
    ...payload,
  });

const getLogList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.getLogList, {
    ...payload,
  });
