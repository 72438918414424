import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useUser } from "../../Services/RQMastersService";
import { AddEditApis } from "../../Services/RQTaskService";
import { userData } from "../../store/slices/user-slice";
import { useSelector } from "react-redux";
interface IAddEditUser {
  data: any;
  onDismiss: (rec: boolean) => void;
  disableFields?: {
    User: boolean;
  };
  id?: string | undefined | null;
}
const AddEditTeamLeader = (props: IAddEditUser) => {
  const [searchFilter, setSearchFilter] = useState<{
    project: {
      search: undefined;
    };
  }>({
    project: {
      search: undefined,
    },
  });
  const user = useSelector(userData);
  const { useProject } = AddEditApis;
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutateAsync: addEditUser, isPending } = useUser();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // console.log("fileList", fileList);
    setFileList(newFileList);
  };
  const [open, setOpen] = useState(true);
  const drawerTitle = props?.data?.id ? "Add Projects" : "Add Projects";

  const [submittable, setSubmittable] = React.useState<boolean>(false);

  const onCancel = (rec: boolean) => {
    form?.resetFields();
    setOpen(!open);
    setTimeout(() => {
      props?.onDismiss(rec);
    }, 300);
  };

  const values = Form.useWatch([], form);
  useEffect(() => {
    // form.setFieldsValue(props?.data);
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    setLoading(false);
  }, [form, values]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setLoading(true);
        let val = {
          id: props?.data?.id,
          ...values,
        };
        //  console.log("val",val);
        let res = await addEditUser(val);

        if (res?.message) {
          console.error("Error =", res?.message);
          message.error("Error-> " + res?.message);
          setLoading(false);
        } else {
          if (props?.data?.id) {
            message.success("User updated successfully!");
          } else {
            message.success("User Added Successfully!");
          }
          form.resetFields();
          onCancel(true);
        }
      })
      .catch(handleError);
  };

  const handleError = (error: any) => {
    // Handle error
  };

  useEffect(() => {
    form.setFieldsValue({
      ...props?.data,
    });
  }, []);
  // console.log("propsData = ",props?.data);

  return (
    <Drawer
      title={drawerTitle}
      extra={
        <CloseOutlined className="cursor" onClick={() => onCancel(false)} />
      }
      closeIcon={false}
      open={open}
      footer={
        <>
          <Row justify={"end"}>
            <Button
              className="capTask-btn-light btn-m-sm"
              onClick={() => onCancel(false)}
            >
              Cancel
            </Button>
            <Button
              className="btn-m-sm"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={!submittable}
            >
              Save
            </Button>
          </Row>
        </>
      }
      width={500}
    >
      <Form
        labelAlign="left"
        autoComplete="off"
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={false}
        initialValues={{ orderNo: 0, isActive: true }}
        className="DrawerPadding"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input
            placeholder="User"
            variant="borderless"
            className="borderLessInput"
            disabled={!!user?.id || props?.disableFields?.User}
          />
        </Form.Item>
        <Form.Item
          label="Projects"
          name="projects"
          rules={[{ required: true, message: "this field is required" }]}
        >
          <Select
            mode="multiple"
            labelInValue
            searchValue={searchFilter?.project?.search}
            variant="borderless"
            className="borderLessInput w100"
            options={projectOptions}
            placeholder="Select Project(s)"
            showSearch
            onSearch={(value) => {
              setSearchFilter((pre: any) => ({
                ...pre,
                project: {
                  search: value,
                },
              }));
            }}
            onBlur={() => {
              setSearchFilter((prev: any) => ({
                ...prev,
                project: {
                  search: null, // Clear the search value on focus
                },
              }));
            }}
            maxTagCount={"responsive"}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                overlayStyle={{ pointerEvents: "none" }}
                title={omittedValues.map(({ label }) => label).join(", ")}
              >
                <span>Hover Me</span>
              </Tooltip>
            )}
            optionFilterProp="label"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEditTeamLeader;
