import {
  Avatar,
  Button,
  Card,
  Table,
  TableColumnsType,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import {
  getEllipsisText,
  statusbackgroundgenerator,
  statusconvertor,
} from "../../utils/Util";
// import AssigneeAvatar from "../../components/commoncomponents/AssigneeAvatar";
import React, { useState } from "react";
import "../../common/common.css";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { AssigneeAvatar } from "../dashboard/Common/AssigneeAvatar";
import { LineOutlined } from "@ant-design/icons";
function IssuesTable({
  reportResult,
  isReportPending,
  searchFilter,
  onChange,
}: any) {
  const navigate = useNavigate();
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };
  // pagination code

  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    console.log(
      "sadddddddddddddddddddddddddddddddddddd = ",
      key,
      " => ",
      value
    );
    onChange(key, value);
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging?.current - 1) * paging?.pageSize);
      changeListParams("length", paging?.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  // Tabel
  const ChildColumns: TableColumnsType<any> = [
    {
      title: "",
      dataIndex: "",
      width: 25,
      key: "",
      fixed: "left",
      render: (text: string, record: any, index: number) => <div></div>,
    },
    {
      title: "S. No.",
      dataIndex: "sno",
      width: 80,
      key: "sno",
      fixed: "left",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: <Typography.Text ellipsis> UKey</Typography.Text>,
      dataIndex: "uKey",
      fixed: "left",
      width: 160,
      // width: "10%",

      render: (_: any, record: any) => (
        <Link className="pt-2" to={`/task/${record?.id}`}>
          <Tooltip title={record?.parent?.uKey}>
            <Typography.Paragraph className="fs13" ellipsis>
              {record?.uKey}
            </Typography.Paragraph>
          </Tooltip>
        </Link>
      ),
    },

    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      showSorterTooltip: false,
      // width: 130,
      sorter: (a: any, b: any) => a.Project - b.Project,
      render: (task: any, record: any) => (
        <Typography.Text
          title={record?.project[0]?.name}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {record?.project[0]?.name}
        </Typography.Text>
      ),
    },

    {
      title: "Task",
      dataIndex: "title",
      key: "title",

      width: 445,
      render: (text, record) => (
        <Typography.Paragraph
          title={record?.title}
          style={{ cursor: "pointer" }}
          ellipsis
          onClick={() => navigate("/task/" + record?.id)}
        >
          {getEllipsisText(record?.title, 60)}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Status",
      dataIndex: "tStatus",
      showSorterTooltip: false,
      // width: "15%",
      align: "center",

      render: (text: any, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          onClick={() => navigate("/task/" + record?.id)}
          style={{
            backgroundColor: statusbackgroundgenerator(
              statusconvertor(record?.tStatus)
            ),
            color: "rgb(12, 84, 96)",
            width: "125px",
            border: "none",
          }}
        >
          <Typography.Text ellipsis style={{ fontSize: "12.5px" }}>
            {statusconvertor(record?.tStatus)}
          </Typography.Text>
        </Button>
      ),
    },
    {
      title: <Typography.Text ellipsis>Assignee</Typography.Text>,
      dataIndex: "assignee",
      width: 100,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.Assignee - b.Assignee,
      render: (_: any, record: any) => (
        <span className="cursorPointer">
          <AssigneeAvatar
            data={Array.isArray(record?.assignee) ? record?.assignee : []}
          />
        </span>
      ),
    },
    {
      title: <Typography.Text ellipsis>Assigned By</Typography.Text>,
      dataIndex: "assignedBy",
      showSorterTooltip: false,
      width: 151,

      // sorter: (a: any, b: any) => a.assignedBy - b.assignedBy,
      render: (_: any, record: any) => (
        <AssigneeAvatar
          showModel={false}
          data={Array.isArray(record?.assignedBy) ? record?.assignedBy : []}
        />
      ),
    },
    {
      title: <Typography.Text ellipsis>Due Date</Typography.Text>,
      dataIndex: "dueDate",
      showSorterTooltip: false,
      width: 165,
      // sorter: (a: any, b: any) => a.dueDate - b.dueDate,
      render: (text: any, record: any) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {moment(record?.dueDate)?.format("DD MMM YYYY") === "01 Jan 0001" ? (
            <LineOutlined />
          ) : (
            moment(record?.dueDate)?.format("DD MMM YYYY")
          )}
        </Typography.Text>
      ),
    },
    {
      title: <Typography.Text ellipsis>Last Updated on</Typography.Text>,
      dataIndex: "updatedBy",
      key: "Last Updated on",

      showSorterTooltip: false,
      // width: "13%",
      // sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (task, record) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {moment(record?.updatedBy?.date).isSame(moment(), "day")
            ? `Today ${moment(record?.updatedBy?.date).format("LT")}` // Show "Today" and time if updated today
            : moment(record?.updatedBy?.date).format("DD MMM YYYY")}
        </Typography.Text>
      ),
    },
  ];
  const ParentColumns: TableColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "sno",
      width: 80,
      key: "sno",
      // width: "5%",
      fixed: "left",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: <Typography.Text ellipsis> UKey</Typography.Text>,
      dataIndex: "uKey",
      fixed: "left",
      width: 150,
      // width: "10%",

      render: (_: any, record: any) => (
        <Link className="pt-2" to={`/task/${record?.parent?.id}`}>
          <Button
            size="small"
            className="resume-btn-sm"
            style={{
              backgroundColor: "rgb(204, 229, 255)",
              width: "6vw",
              border: "none",
            }}
          >
            {priorityIcon(record?.parent?.priority)}

            <Tooltip title={record?.parent?.uKey}>
              <Typography.Paragraph style={{ fontSize: "12px" }} ellipsis>
                <span style={{ color: "#0c5460" }}>{record?.parent?.uKey}</span>
              </Typography.Paragraph>
            </Tooltip>
          </Button>
        </Link>
      ),
    },

    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a?.task?.localeCompare(b?.task),
      render: (task: any, record: any) => (
        <Typography.Text
          title={record?.parent?.project[0]?.name}
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.parent?.id)}
          ellipsis
        >
          {record?.parent?.project[0]?.name}
        </Typography.Text>
      ),
    },

    {
      title: "Task",
      dataIndex: "title",
      key: "title",
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.Task - b.Task,
      width: "15%",
      render: (text, record) => (
        <Typography.Paragraph
          title={record?.parent?.title}
          style={{ cursor: "pointer" }}
          ellipsis
          onClick={() => navigate("/task/" + record?.parent?.id)}
        >
          {getEllipsisText(record?.parent?.title, 60)}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Status",
      dataIndex: "tStatus",
      showSorterTooltip: false,
      // width: "15%",
      align: "center",
      // sorter: (a: any, b: any) => a.Status - b.Status,

      render: (text: any, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          onClick={() => navigate("/task/" + record?.parent?.id)}
          style={{
            backgroundColor: statusbackgroundgenerator(
              statusconvertor(record?.parent?.tStatus)
            ),
            color: "rgb(12, 84, 96)",
            width: "125px",
            border: "none",
          }}
        >
          <Typography.Text ellipsis style={{ fontSize: "12.5px" }}>
            {statusconvertor(record?.parent?.tStatus)}
          </Typography.Text>
        </Button>
      ),
    },
    {
      title: <Typography.Text ellipsis>Assignee</Typography.Text>,
      dataIndex: "assignee",
      width: 100,
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => a.Assignee - b.Assignee,
      render: (_: any, record: any) => (
        <span className="cursorPointer">
          <AssigneeAvatar
            data={
              Array.isArray(record?.parent?.assignee)
                ? record?.parent?.assignee
                : []
            }
          />
        </span>
      ),
    },
    {
      title: <Typography.Text ellipsis>Assigned By</Typography.Text>,
      dataIndex: "assignedBy",
      showSorterTooltip: false,
      width: 150,

      // sorter: (a: any, b: any) => a.assignedBy - b.assignedBy,
      render: (_: any, record: any) => (
        <AssigneeAvatar
          showModel={false}
          data={
            Array.isArray(record?.parent?.assignedBy)
              ? record?.parent?.assignedBy
              : []
          }
        />
      ),
    },
    {
      title: <Typography.Text ellipsis>Due Date</Typography.Text>,
      dataIndex: "dueDate",
      showSorterTooltip: false,
      // width: "13%",
      // sorter: (a: any, b: any) => a.dueDate - b.dueDate,
      render: (text: any, record: any) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.parent?.id)}
          ellipsis
        >
          {/* {moment(record?.parent?.dueDate)?.format("DD MMM yyyy")} */}
          {moment(record?.parent?.dueDate)?.format("DD MMM yyyy") ===
          "01 Jan 0001" ? (
            <LineOutlined />
          ) : (
            moment(record?.parent?.dueDate)?.format("DD MMM yyyy")
          )}
        </Typography.Text>
      ),
    },
    {
      title: <Typography.Text ellipsis>Last Updated on</Typography.Text>,
      dataIndex: "updatedBy",
      key: "Last Updated on",

      showSorterTooltip: false,
      // width: "13%",
      // sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (task, record) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.parent?.id)}
          ellipsis
        >
          {moment(record?.parent?.updatedBy?.date).isSame(moment(), "day")
            ? `Today ${moment(record?.parent?.updatedBy?.date).format("LT")}` // Show "Today" and time if updated today
            : moment(record?.parent?.updatedBy?.date).format("DD MMM YYYY")}
        </Typography.Text>
      ),
    },
  ];
     

  return (
    <div className="mt-2 mr-4 ml-4">
      {isReportPending ? (
        <TableSkeleton
          columns={ParentColumns}
          scroll={{ y: "75vh" }}
          style={{
            scrollbarWidth: "none",
            borderTop: "solid 1px #b2bec3",
          }}
        />
      ) : (
        <Table
          style={{ borderTop: "solid 1px #b2bec3" }}
          scroll={{ x: "max-content", y: "71vh" }}
          columns={ParentColumns}
          dataSource={reportResult?.items}
          rowKey={(record) => record.id || record.key}
          onChange={onChangeTable}
          pagination={{
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            current: searchFilter?.start / searchFilter?.length + 1,
            pageSize: searchFilter?.length,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
              total:reportResult?.totalRecords

          }}
          expandable={{
            expandedRowRender: (record) => (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  backgroundColor: "white",
                  borderRadius: 5,
                }}
              >
                <Typography.Text ellipsis className="ml-2 mt-2 fw600">
                  Child Task(s)
                </Typography.Text>
                <Table
                  className="mt-1 issues capTask-custom-table"
                  showHeader={false}
                  columns={ChildColumns}
                  dataSource={record?.childs}
                        />
              </div>
            ),
            rowExpandable: (record: any) => {
              if (!!!record?.id) return false;
              else if (record?.childs?.length >= 1) return true;
              else return false;
            },
            showExpandColumn: searchFilter?.taskType === 2,
          }}
          className="issuestabel capTask-custom-table"
        />
      )}
      
    </div>
  );
}
export default IssuesTable;
