import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Input, Button, Spin, message, Divider } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./Changepassword.css";
import { userData } from "../store/slices/user-slice";
import { useSelector } from "react-redux";
import HTTPSCalls from "../Services/HTTPCalls";

const Changepassword = ({ openModal, closeModal, isOpen, setIsOpen }: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(userData);
  const [form] = Form.useForm(); // Using form instance from Ant Design

  const handleSubmit = async () => {
    try {
      // Trigger form validation
      await form.validateFields();

      const { oldpassword, newpassword, confirmpassword } = form.getFieldsValue();

      if (newpassword === oldpassword) {
        message.error("Current password and new password cannot be the same");
        return;
      }

      if (newpassword !== confirmpassword) {
        message.error("Passwords do not match");
        return;
      }

      setLoading(true);
      const res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.changePassword,{
        oldpassword,
        newpassword,
        uid: user?.id,
      })
      if (res.result === "Password changed successfully.") {
        message.success("Password changed successfully");
        navigate("/");
        closeModal();
      } else {
        message.error("Error occurred, can't reset password");
      }
    } catch (error) {
      // Validation failed, error message will be displayed by Ant Design automatically
      // message.error("An error occurred, please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maskClosable={false}
      title="Change Password"
      open={isOpen}
      width={450}
      onCancel={closeModal}
      footer={[
        <Button
          htmlType="submit"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Divider className="mt-1 mb-3" />
      <Form
        form={form}
        name="wrap"
        labelCol={{ flex: "160px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
      >
        <Form.Item
          label="Current password"
          name="oldpassword"
          rules={[{ required: true, message: "This filed is required" }]}
        >
          <Input.Password
            variant="borderless"
            className="borderLessInput w100"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newpassword"
          rules={[{ required: true, message: "This filed is required" }]}
        >
          <Input.Password
            variant="borderless"
            className="borderLessInput w100"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmpassword"
          rules={[{ required: true, message: "This filed is required" }]}
        >
          <Input.Password
            variant="borderless"
            className="borderLessInput w100"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Changepassword;
