import {
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  message,
  Progress,
  Row,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useAssigneeContributionList } from "../../Services/RQDashboardService";
import TableSkeleton from "../../pages/dashboard/Common/TableSkeleton";
import { Link } from "react-router-dom";
import { AppRoutesPath } from "../../Routes/AppRoutesPath";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { number } from "echarts";
import { dayjs } from "../../utils/dayjs";

interface IAssigneeContribution {
  projectId: string | undefined;
}
const AssigneeContribution = ({ projectId }: IAssigneeContribution) => {
  const [listParams, setListParams] = useState({
    AssigneeNameSearch: "",
  });
  const [searchAssigneePayload, setSearchAssigneePayload] = useState<{
    fromYear: number;
    toYear: number;
  }>({
    fromYear: dayjs().year(),
    toYear: dayjs().year(),
  });
  const { data, isFetching } = useAssigneeContributionList(
    projectId,
    searchAssigneePayload
  );
  const searchTerm = listParams?.AssigneeNameSearch?.trim().toLowerCase();
  const list = useMemo(() => {
    if (data?.message) {
      message.error(`Error in => ${data?.message}`);
    }
    return (
      data?.result?.filter(
        (x: any) =>
          !searchTerm || x?.assignee?.name?.toLowerCase().includes(searchTerm)
      ) || []
    );
  }, [data, searchTerm]);

  const handleSearch = useCallback(
    _.debounce((value: string) => {
      setListParams((prev) => ({
        ...prev,
        AssigneeNameSearch: value,
      }));
    }, 300),
    []
  );

  const getStatus = useCallback(
    (per: any) => {
      if (per === 0) return "red";
      else if (per >= 0 && per <= 25) return "#ff4d4f";
      else if (per > 25 && per <= 75) return "#1677ff";
      else return "#52c41a";
    },
    [list]
  );

  const columns: any = [
    {
      title: <Typography.Text>S. No.</Typography.Text>,
      dataIndex: "serial",
      key: "serial",
      width: 25,
      ellipsis: true,

      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a?.percentage - b?.percentage,
      width: 140,
      ellipsis: true,
      showSorterTooltip: false,
      render: (name: any, record: any) => (
        <Row gutter={[0, 0]} justify={"space-between"} align={"middle"}>
          <Col span={12}>
            <Link
              to={`/${AppRoutesPath?.reports?.home}/${AppRoutesPath?.reports?.userReport}?userid=${record?.id}`}
              state={{ userid: record?.id }}
            >
              <Typography.Paragraph ellipsis title={record?.assignee?.name}>
                {record?.assignee?.name}
              </Typography.Paragraph>
            </Link>
          </Col>
          <Col span={12}>
            <Progress
              status={
                getStatus(record?.percentage) === "red" ? "exception" : "active"
              }
              strokeColor={getStatus(record?.percentage)}
              percent={record?.percentage}
              size="small"
            />
          </Col>
        </Row>
      ),
    },
  ];


  return (
    <Card
      // className="mt-2 mr-3 "
            className=" Resposivecostom mr-3 mt-2 "
      style={{
        borderRadius: 12,
        padding: "14px 8px 14px 14px",
        height: 275
      }}
    >
      <Row className=" mr-3">
        <Col span={24}>
          <Row justify={"end"} className="mb-1">
            <Col span={24}>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      colorTextPlaceholder: "#95a5a6",
                      fontSize: 14,
                      colorPrimaryHover: "none",
                      fontSizeIcon: 10,
                      borderRadius: 4,
                      controlPaddingHorizontal: 4,
                      controlHeight: 27,
                      algorithm: true,
                      fontWeightStrong: 600,
                    },
                    DatePicker: {
                      controlHeight: 27,
                      controlHeightLG: 27,

                      hoverBorderColor: "#dee2e6",

                      activeBorderColor: "#dee2e6",
                    },
                  },
                }}
              >
                <Row align={"middle"} justify={"space-between"}>
                  <Col xxl={7} xl={5} lg={3}  className="fw600">
                    <Typography.Paragraph ellipsis>
                      Assignee Contribution
                    </Typography.Paragraph>
                  </Col>

                  <Col xxl={8} xl={8} lg={5}  >
                    <Input
                      suffix={<SearchOutlined />}
                      placeholder="Search..."
                      allowClear
                      onChange={(e) => handleSearch(e?.target?.value)}
                     variant="borderless"
                      className="w100"
                      type="text"
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      disabledDate={(current) => {
                        // Can not select days before today and today
                        return current && current >= dayjs();
                      }}
                      variant="borderless"
                      defaultValue={dayjs()}
                      allowClear={false}
                      onChange={(date) => {
                        setSearchAssigneePayload((pre: any) => ({
                          ...pre,
                          fromYear: date.year(),
                          toYear: date.year(),
                        }));
                      }}
                      picker="year"
                    />
                  </Col>
                </Row>
              </ConfigProvider>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          {isFetching ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "14vh" }}
              rows={5}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              dataSource={list}
              columns={columns}
              pagination={false}
              scroll={{ y: 185 }}
              style={{
                borderTop: "solid 1px #b2bec3",
              }}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingBottom: "28px", width: 110, marginTop: 40 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default AssigneeContribution;
