import React, { Suspense, useEffect, useMemo, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppRoutesPath } from "./AppRoutesPath";
import Dashboard from "../pages/dashboard/Dashboard";
import UserReport from "../pages/UserReport";
import Report from "../pages/Report";
import Project from "../pages/Project";
import Issue from "../pages/Issue";
import Sprint from "../pages/Sprint";
import Branch from "../pages/Branch";
import User from "../pages/User";
import DashboardMain from "../pages/Dashboardtablecomponent/DashboardMain";
import Backlog from "../pages/Backlog";
import InHandReport from "../pages/InHandReport";
import ViewAll from "../pages/ViewAll";
import { useSelector } from "react-redux";
import { resetUserData, userData } from "../store/slices/user-slice";
import { useDispatch } from "react-redux";
import { isTokenNotExpired } from "../common/commonFunction";
import NotFound from "../pages/NotFoundpage";
import ViewAllTaskbord from "../pages/ViewAllTaskbord";
import { Setting } from "../pages/setting/Setting";
import Profile from "../components/profile";
import { isUserAdmin, isUserTeamLeader } from "../utils/Util";
import Reports from "../components/Reports/Report";
import Board from "../pages/Board";
import IssuesReport from "../pages/UserDetail/UpcomingMeeting/IssuesReport";
import ProjectGroup from "../pages/setting/ProjectGroup";
import WeeklySummery from "../pages/UserDetail/WeeklySummery";
import WeeklyActivityModel from "../pages/dashboard/LogDetail/WeeklyActivityModel";
import { useAssigneeLastActivity } from "../Services/RQUserService";
import { dayjs } from "../utils/dayjs";
import GithubAuthIntigrate from "../pages/GithubAuth/GithubAuthIntigrate";
import TeamLeader from "../pages/setting/TeamLeader";
const AsyncMainLayout = React.lazy(() => import("../components/MainLayout"));
type RouteConfig = {
  caseSensitive: boolean;
  path: string;
  element: React.ReactNode;
};
const App = () => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const user = useSelector(userData);
  let daysInactive = undefined;
  const { data: LastActivity } = useAssigneeLastActivity(user?.id);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleInactivityCheck = () => {
    if (LastActivity?.result?.createdBy?.date) {
      const activityDate = dayjs(LastActivity?.result?.createdBy?.date);
      daysInactive = dayjs().diff(activityDate, "day");
      const daysThreshold = 7;
      if (daysInactive >= daysThreshold) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    const normalizedPath = location.pathname.replace(/\/$/, "");
    if (user?.token) {
      if (isTokenNotExpired(user?.token)) {
        if (location.pathname === "/" || normalizedPath === "/login") {
          navigate("/dashboard");
        }
      } else {
        dispatch(resetUserData());
        navigate("/login", { replace: true });
      }
    } else if (location.pathname === "/") {
      navigate("/login", { replace: true });
    } else {
      navigate("/login");
    }
  }, [location.pathname, navigate, user, dispatch, refresh]);

  if (handleInactivityCheck() && !isUserAdmin())
    return (
      <WeeklyActivityModel
        daysInactive={daysInactive}
      />
    );
  return (
    <Suspense fallback={<div className="loadinglazy"></div>}>
      <Outlet />
    </Suspense>
  );
};
const ApplicationRoutes = () => {
  const isAdmin = isUserAdmin();
  const isTeamLeader = isUserTeamLeader();
  const routeDefinitions: RouteConfig[] = useMemo(() => {
    return [
      {
        caseSensitive: true,
        path: AppRoutesPath.dashboard,
        element: <Dashboard />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.viewall,
        element: <ViewAll />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.taskboard,
        element: <ViewAllTaskbord />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.profile,
        element: <Profile />,
      },

      {
        caseSensitive: true,
        path: AppRoutesPath.board,
        element: <Board />,
      },

      {
        caseSensitive: true,
        path: AppRoutesPath.backLog,
        element: <Backlog />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.peoples,
        element: <UserReport />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.gitHib.integrate,
        element: <GithubAuthIntigrate />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.reports.home,
        element: <Reports />,
      },
      {
        caseSensitive: true,
        path: AppRoutesPath.task,
        element: <DashboardMain />,
      },
      {
        caseSensitive: true,
        path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.issues}`,
        element: <IssuesReport />,
      },
      {
        caseSensitive: true,
        path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.inHand}`,
        element: <InHandReport />,
      },
      {
        caseSensitive: true,
        path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`,
        element: <Report />,
      },
      {
        caseSensitive: true,
        path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.summery}`,
        element: <WeeklySummery />,
      },
      {
        caseSensitive: true,
        path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.weeklyactivitymodel}`,
        element: <WeeklyActivityModel />,
      },
    ];
  }, []);

  const adminRoutes: RouteConfig[] = [
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.project}`,
      element: <Project />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.issue}`,
      element: <Issue />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.sprint}`,
      element: <Sprint />,
    },

    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.branch}`,
      element: <Branch />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.user}`,
      element: <User />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.group}`,
      element: <ProjectGroup />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.teamleader}`,
      element: <TeamLeader/>,
    },
    {
      caseSensitive: true,
      path: AppRoutesPath.setting.home,
      element: <Setting />,
    },
  ];
  const TeamLeaderRoutes: RouteConfig[] = [
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.project}`,
      element: <Project />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.issue}`,
      element: <Issue />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.sprint}`,
      element: <Sprint />,
    },

    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.branch}`,
      element: <Branch />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.user}`,
      element: <User />,
    },
    {
      caseSensitive: true,
      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.group}`,
      element: <ProjectGroup />,
    },
    
    {
      caseSensitive: true,
      path: AppRoutesPath.setting.home,
      element: <Setting />,
    },
  ];
  return (
    <Routes>
      <Route path="" element={<App />}>
        {routeDefinitions.map((route) => (
          <Route
            key={route.path}
            caseSensitive={route.caseSensitive}
            path={route.path}
            element={route.element}
          />
        ))}

        {/* Admin routes: Render only if the user is an admin */}
        {isAdmin &&
          adminRoutes.map((route) => (
            <Route
              key={route.path}
              caseSensitive={route.caseSensitive}
              path={route.path}
              element={route.element}
            />
          ))}
        {isTeamLeader &&
          TeamLeaderRoutes.map((route) => (
            <Route
              key={route.path}
              caseSensitive={route.caseSensitive}
              path={route.path}
              element={route.element}
            />
          ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ApplicationRoutes;
