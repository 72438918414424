import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Drawer,
  Flex,
  Form,
  GetProp,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useProject } from "../../Services/RQMastersService";
import { CloseOutlined } from "@ant-design/icons";
import "../../common/common.css";
import ImgCrop from "antd-img-crop";
import { AddEditApis } from "../../Services/RQTaskService";
const AddEditProject = (props: any) => {
  const { mutateAsync: addEditProject, isPending } = useProject();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const { useUser } = AddEditApis;
  const [open, setOpen] = useState(true);
  const [form] = Form.useForm();
  // const [AllAssignee, setAllAssignee] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const drawerTitle = props?.data?.id ? "Edit Project" : "Add Project";
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<{
    user: {
      search: undefined;
    };
  }>({
    user: {
      search: undefined,
    },
  });
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);
  useEffect(() => {
    form?.setFieldsValue(props?.data);
  }, []);
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        let val = {
          ...values,
          id: props?.data?.id,
          assigneeIds: values?.assigneeIds?.map((itm: any) => itm?.value),
          imageThumbPath: "",
          imagePath: fileList[0]?.thumbUrl,
          teamleaderIds:
            values?.teamleaderIds?.map((itm: any) => itm?.value) || [],
          company: "63a959074e041fdc2ae91ce8",
          statusList: [
            { name: "TODO", indexNo: 1 },
            { name: "NEED TO DISCUSS", indexNo: 2 },
            { name: "IN PROGRESS", indexNo: 3 },
          ],
        };

        try {
          setLoading(true);
          let res = await addEditProject(val);

          if (res?.message) {
            console.error("Error =", res?.message);
            message.error("Error-> " + res?.message);
          } else {
            // Display edit time or submit time message
            if (props?.data?.id) {
              message.success("Project updated Successfully");
            } else {
              // New project submission
              message.success("Project Added Successfully");
            }

            form.resetFields();
            onCancel();
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          handleError(error);
        } finally {
          setLoading(false);
        }
      })
      .catch(handleError);
  };

  const onCancel = () => {
    form.resetFields();
    setOpen(!open);
    setTimeout(() => {
      props?.onDismiss();
    }, 300);
  };
  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })

      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    setLoading(false);
  }, [form, values]);

  const statusOption = [
    { label: "Active", value: 0 },
    { label: "Inactive", value: 1 },
  ];

  const handleError = (error: any) => {
    // Handle error
  };
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  const uploadRef = useRef<any>();
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader?.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  return (
    <Drawer
      title={drawerTitle}
      extra={<CloseOutlined className="cursor" onClick={onCancel} />}
      closeIcon={false}
      open={open}
      footer={
        <>
          <Row justify={"end"}>
            <Button className="capTask-btn-light btn-m-sm" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="btn-m-sm"
              type="primary"
              onClick={handleSubmit}
              loading={Loading}
              disabled={!submittable}
            >
              Save
            </Button>
          </Row>
        </>
      }
      width={500}
    >
      <Form
        form={form}
        labelAlign="left"
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={false}
        initialValues={{ orderNo: 0 }}
        className="DrawerPadding"
      >
        <Form.Item
          label="Name"
          name="name"
          required
          rules={[
            { required: true, message: "Please input your project name!" },
          ]}
        >
          <Input
            variant="borderless"
            className="borderLessInput"
            placeholder="Project Name"
          />
        </Form.Item>

        <Form.Item
          label="Alias"
          required
          name="alias"
          rules={[{ required: true, message: "Please input an alias!" }]}
        >
          <Input
            variant="borderless"
            className="borderLessInput"
            placeholder="Alias Name"
          />
        </Form.Item>
        <Form.Item
          label="Order"
          required
          name="orderNo"
          rules={[
            { required: true, message: "Please input the order number!" },
            {
              type: "number",
              min: 0,
              message: "Order number cannot be negative!",
            },
          ]}
        >
          <InputNumber variant="borderless" className="borderLessInput" />
        </Form.Item>

        <Form.Item
          label="Assignee"
          required
          name="assigneeIds"
          rules={[
            { required: true, message: "Please select at least one assignee!" },
          ]}
        >
          <Select
            className="borderLessInput"
            placeholder="Select Assignee"
            options={reporterOptions}
            loading={isUserPending}
            searchValue={searchFilter?.user?.search}
            mode="multiple"
            maxTagCount="responsive"
            labelInValue
            variant="borderless"
            showSearch
            onSearch={(value) => {
              setSearchFilter((pre: any) => ({
                ...pre,
                user: {
                  search: value,
                },
              }));
            }}
            onBlur={() => {
              setSearchFilter((prev: any) => ({
                ...prev,
                user: {
                  search: null, // Clear the search value on focus
                },
              }));
            }}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                overlayStyle={{ pointerEvents: "none" }}
                title={omittedValues
                  .map(({ label }) => label)
                  .join(", ")}
              >
                <span>Hover Me</span>
              </Tooltip>
            )}
            optionFilterProp="label"
          />
        </Form.Item>

        {/* <Form.Item
    label="Teamleader"
    required
    name="teamleaderIds"
  >
    <Select
      className="borderLessInput"
      placeholder="Select Assignee"
      options={reporterOptions}
      mode="multiple"
      maxTagCount="responsive"
      labelInValue
      variant="borderless"
      showSearch
      onSearch={onSearch}
      optionFilterProp="label"
    />
  </Form.Item> */}

        <Form.Item
          label="Status"
          required
          name="projectStatus"
          rules={[{ required: true, message: "Please select a status!" }]}
        >
          <Select
            placeholder="Select Status"
            options={statusOption}
            variant="borderless"
            className="borderLessInput"
          />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <TextArea
            className="borderLessInput"
            variant="borderless"
            autoSize={{ minRows: 2 }}
          />
        </Form.Item>

        <Form.Item label="Attachment" name="imagePath">
          <ImgCrop rotationSlider>
            <Upload
              ref={uploadRef}
              className="uploadimg"
              action="v1/api/user/profile"
              listType="picture"
              beforeUpload={() => {
                return false;
              }}
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
            >
              {fileList?.length < 1 && (
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/imgdrop.png"}
                    alt="Upload Icon"
                    style={{
                      width: "30%",
                      marginBottom: 8,
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEditProject;
