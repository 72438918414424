import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Flex,
  Form,
  GetProp,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useGetAllAssigneeList } from "../../../Services/RQDashboardService";
import { useProject, useUser } from "../../../Services/RQMastersService";
import {
  CloseOutlined,
  CrownOutlined,
  InboxOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../../../common/common.css";
import HTTPSCalls from "../../../Services/HTTPCalls";
interface IAddEditUser {
  data: any;
  onDismiss: (rec: boolean) => void;
}
const AddEditUser = (props: IAddEditUser) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { mutateAsync: addEditUser, isPending } = useUser();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    // console.log("fileList", fileList);
    setFileList(newFileList);
  };
  const [open, setOpen] = useState(true);
  const drawerTitle = props?.data?.id ? "Edit User" : "Add User";
  //  console.log(props.data?.id, "data");

  const [submittable, setSubmittable] = React.useState<boolean>(false);

  const onCancel = (rec: boolean) => {
    form?.resetFields();
    setOpen(!open);
    setTimeout(() => {
      props?.onDismiss(rec);
    }, 300);
  };

  const values = Form.useWatch([], form);
  useEffect(() => {
    // form.setFieldsValue(props?.data);
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    setLoading(false);
  }, [form, values]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setLoading(true);
        let val = {
          id: props?.data?.id,
          ...values,
        };
        //  console.log("val",val);
        let res = await addEditUser(val);

        if (res?.message) {
          console.error("Error =", res?.message);
          message.error("Error-> " + res?.message);
          setLoading(false);
        } else {
          if (props?.data?.id) {
            message.success("User updated successfully!");
          } else {
            message.success("User Added Successfully!");
          }
          form.resetFields();
          onCancel(true);
        }
      })
      .catch(handleError);
  };

  const handleError = (error: any) => {
    // Handle error
  };
  // Role options with icons
  const roleOption = [
    {
      label: (
        <>
          <UserOutlined /> Staff
        </>
      ),
      value: "Staff",
    },
    {
      label: (
        <>
          <TeamOutlined /> Team leader
        </>
      ),
      value: "TeamLeader",
    },
    {
      label: (
        <>
          <CrownOutlined /> Admin
        </>
      ),
      value: "Admin",
    },
  ];
  useEffect(() => {
    form.setFieldsValue({
      ...props?.data,
    });
  }, []);
  // console.log("propsData = ",props?.data);

  return (
    <Drawer
      title={drawerTitle}
      extra={
        <CloseOutlined className="cursor" onClick={() => onCancel(false)} />
      }
      closeIcon={false}
      open={open}
      footer={
        <>
          <Row justify={"end"}>
            <Button
              className="capTask-btn-light btn-m-sm"
              onClick={() => onCancel(false)}
            >
              Cancel
            </Button>
            <Button
              className="btn-m-sm"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={!submittable}
            >
              Save
            </Button>
          </Row>
        </>
      }
      width={500}
    >
      <Form
        labelAlign="left"
        autoComplete="off"
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={false}
        initialValues={{ orderNo: 0, isActive: true , }}
        className="DrawerPadding"
      
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input
            placeholder="User"
            variant="borderless"
            className="borderLessInput"
          />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select
            placeholder="Select..."
            options={roleOption}
            variant="borderless"
            className="borderLessInput"
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Enter a valid email address!",
            },
          ]}
        >
          <Input
            placeholder="Email Address"
            variant="borderless"
            className="borderLessInput"
          />
        </Form.Item>

        {!!!props?.data ? (
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter password" }]}
          >
            <Input.Password
              placeholder="Password"
              variant="borderless"
              className="borderLessInput"
            />
          </Form.Item>
        ) : null}

        <Form.Item label="Active" name="isActive" valuePropName="checked">
          <Checkbox defaultChecked={true}>Active</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEditUser;
