import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useGetPendingTasks } from "../../../Services/RQDashboardService";
import moment from "moment";
import {
  Avatar,
  Button,
  Col,
  ConfigProvider,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";

import { initialsgenerator, textnumbergenerator } from "../../../utils/Util";
import { userData } from "../../../store/slices/user-slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../Common/TableSkeleton";
import { PendingTaskModel } from "./PendingTaskModel";
import HTTPSCalls from "../../../Services/HTTPCalls";
interface PendingTaskProps {
  page?: string;
  y?: string | number;
  params?: any;
  props: any;
  projectdata: any;
  setShow: any;
  setSelectedAssignees: any;
}
const PendingTask: React.FC<PendingTaskProps> = ({
  page,
  y,
  params,
  props,
  projectdata,
  setShow,
  setSelectedAssignees,
  // setSelectedAssignees,
  // setIsTableVisible
}) => {
  const user = useSelector(userData);
  const { data: taskData, isFetching: isTaskLoading } = useGetPendingTasks(
    { assigneeId: user?.id },
    user?.id
  );
  const navigate = useNavigate();
  const [projectdatapending, setprojectdatapending] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeDrawer = useCallback(() => setIsOpen(false), []);

  let RANDOM_USERS: any = [];

  for (let el in projectdata) {
    RANDOM_USERS.push({ id: projectdata[el].id, name: projectdata[el].name });
  }

  const data = RANDOM_USERS.map((d: any, i: any) => ({
    email: d.name,
    key: d.name,
    name: d.name,
    href: "#",
    src:
      d.imageThumbPath ||
      `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
        d.name
      )}-${textnumbergenerator(d.name)}.png?ssl=1`,
  }));

  const showPendingTask = (projectId: any, data: any) => {
    setisLoading(true);
    HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getPendingTaskList + "?", {
      start: 0,
      length: 250,
      projectId: projectId,
      isDone: 1,
      assigneeId: user?.id,
    }).then((res: any) => {
      setisLoading(false);
      setprojectdatapending(res.result);
    });
  };
  const getPendingTaskList = useMemo(() => {
    return taskData?.result?.items?.map((task: any) => ({
      ...task,
      assignee: task?.assignee?.map((itm: any) => ({
        ...itm,
        thumb: itm?.imageThumbPath,
      })),
    }));
  }, [taskData]);

  const columns: any = [
    {
      title: <Typography.Text>S. No.</Typography.Text>,
      dataIndex: "serial",
      key: "serial",
      width: 80,
      ellipsis: true,

      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      width: 160,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (name: any, record: any) => (
        <Typography.Link
          onClick={() => {
            setprojectdatapending([]);
            showPendingTask(record?.id, record?.name);
            openModal();
          }}
        >
          <Typography.Text title={name}>
            <span style={{ color: "#1677ff" }}>{name}</span>
          </Typography.Text>
        </Typography.Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      width: 120,
      showSorterTooltip: false,
      sorter: (a: any, b: any) =>
        moment(a.createDate).unix() - moment(b.createDate).unix(),
      render: (date: any) => (
        <Typography.Text>{moment(date).format("DD MMM YYYY")}</Typography.Text>
      ),
    },
    {
      title: "Team",
      dataIndex: "assignee",
      key: "assignee",
      width: 110,
      ellipsis: true, // Enables ellipsis
      render: (assignee: any, item: any) => (
        <Button
          className="dash-link"
          type="link"
          style={{ color: "rgb(54, 70, 99)", padding: 0 }}
          onClick={() => {
            setShow("Assignees");
            setSelectedAssignees(item?.assignee);
          }}
        >
          {item?.assignee?.length} Assignee
        </Button>
      ),
    },
  ];

  return (
    <Row className="roundedCornerSmall bg-white mr-2 Resposivecostom Resposivecostom1">
      <Col span={24}>
        <Row justify={"end"} className="mb-2">
          <Col span={24}>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    colorTextPlaceholder: "#95a5a6",
                    fontSize: 14,
                    colorPrimaryHover: "none",
                    fontSizeIcon: 10,
                    borderRadius: 4,
                    controlPaddingHorizontal: 4,
                    controlHeight: 27,
                    algorithm: true,
                    fontWeightStrong: 600,
                  },
                },
              }}
            >
              <Row>
                <Col span={24} className="fw600">
                  Pending Tasks
                </Col>
              </Row>
            </ConfigProvider>
          </Col>
          <PendingTaskModel
            projectdatapending={projectdatapending}
            isLoading={isLoading}
            isOpen={isOpen}
            closeDrawer={closeDrawer}
          />
        </Row>
      </Col>

      <Col span={24}>
        {isTaskLoading ? (
          <TableSkeleton
            columns={columns}
            scroll={{ y: "75vh" }}
            rows={7}
            style={{
              scrollbarWidth: "none",
              borderTop: "solid 1px #b2bec3",
            }}
          />
        ) : (
          <Table
            dataSource={getPendingTaskList}
            columns={columns}
            pagination={false}
            scroll={{ y: 255 }}
            style={{
              borderTop: "solid 1px #b2bec3",
            }}
            locale={{
              emptyText: (
                <img
                  style={{ paddingBottom: "70px", width: 110, marginTop: 70 }}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              ),
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default React.memo(PendingTask);
