import { Breadcrumb, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import SummeryTable from "../IssuesTable/SummeryTable";
import { useLastActivityModelId } from "../../Services/RQUserService";
import { useEffect, useMemo, useState } from "react";
import { userData } from "../../store/slices/user-slice";
import { useSelector } from "react-redux";

interface WeeklyReasonItem {
  id: string;
  title: string;
  date: string;
}
const WeeklySummery = () => {
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
    UserNameSearch: "",
  });
  const searchTerm = listParams?.UserNameSearch?.trim().toLowerCase();
  const navigate = useNavigate();
  const user = useSelector(userData);

  const isAdmin = user?.role === "Admin" || user?.role === "TeamLeader";
  useEffect(() => {
    if (!isAdmin) {
      navigate("/dashboard");
    }
  }, []);
  const {
    data: WeeklyReason,
    isPending: isWeeklyReasonPending,
    error,
  } = useLastActivityModelId(
    user?.role === "Admin" ? null : user?.id,
    user?.role === "Admin"
  );

  const WeeklyReasonList: WeeklyReasonItem[] = useMemo(() => {
    if (!WeeklyReason?.result) return [];
    return (
      WeeklyReason?.result?.filter(
        (x: any) =>
          !searchTerm ||
          x?.createdBy?.userName?.toLowerCase().includes(searchTerm)
      ) || []
    );
  }, [WeeklyReason, searchTerm]);

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/reports"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/reports"} className="BreadcrumbLink">
                    Reports
                  </Link>
                ),
              },
              {
                title: "Summery",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white">
        <Col span={24}>
          <SummeryTable
            isWeeklyReasonPending={isWeeklyReasonPending}
            WeeklyReason={WeeklyReasonList}
            setListParams={setListParams}
            listParams={listParams}
          />
        </Col>
      </Row>
    </>
  );
};

export default WeeklySummery;
