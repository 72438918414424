import React, { useRef, useState } from "react";
import { Upload, Button, Modal, GetProp, Row, Col, message } from "antd";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { userData } from "../store/slices/user-slice";
import { useSelector } from "react-redux";
import HTTPSCalls from "../Services/HTTPCalls";

const UploadComponent = ({ isUpload, closeUpload }: any) => {
  const [isOpenMode, setIsOpenModel] = useState(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const uploadRef = useRef<any>();
  const onPreview = async (file: UploadFile) => {
    let src = file?.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader?.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const user = useSelector(userData);
  const handleSubmit = async () => {
    setLoading(true); // Show loading spinner
    try {
      if (!fileList[0]?.thumbUrl) {
        message.error("Please upload a valid profile picture", 2);
        setLoading(false);
        return;
      }

      const data = {
        userId: user?.id,
        name: user?.name,
        email: user?.email,
        imagePath: fileList[0]?.thumbUrl,
      };
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.user.userProfile + "?",
        data
      );

      if (res?.result) {
        message.success("Profile picture updated successfully", 2);
        setLoading(false);
        setIsOpenModel(false);
      } else if (res?.errors) {
        message.error("Error occurred", 2);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      message.error("An unexpected error occurred", 2);
    }
  };
  return (
    <Modal
      className="modelupload"
      width={300}
      maskClosable={true}
      title={
        <span
          style={{ fontWeight: 500, fontSize: 17, fontFamily: "Open Sans" }}
        >
          Upload an avatar
        </span>
      }
      open={isUpload && isOpenMode}
      onCancel={closeUpload}
      footer={[
        <Button
          key="cancel"
          onClick={closeUpload}
          // style={{padding:'5px 5px'}}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
          disabled={!fileList?.length}
        >
          Save
        </Button>,
      ]}
    >
      <Row justify="center">
        <Col
          span={12}
          style={{
            margin: "33px 10px",
          }}
        >
          <Upload
            ref={uploadRef}
            className="uploadimg"
            action="v1/api/user/profile"
            listType="picture-card"
            beforeUpload={() => {
              return false;
            }}
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList?.length < 1 && (
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/imgdrop.png"}
                  alt="Upload Icon"
                  style={{
                    width: "40%",
                    marginBottom: 8,
                    borderRadius: "100%",
                  }}
                />
                <div>Drag and drop your images here</div>
              </div>
            )}
          </Upload>
        </Col>
      </Row>
    </Modal>
  );
};

export default UploadComponent;
