import React, { useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  Row,
  Select,
  Table,
  TableProps,
  Typography,
} from "antd";
// import { TableLoadingSpinner } from '../AntDashboard/Common/TableLoadingSpinner';
// import { AssigneeAvatar } from '../AntDashboard/Common/AssigneeAvatar';
import AddEditSprint from "./AddEditSprint";
import { useGetSprintList, useProject } from "../../Services/RQMastersService";
import { useGetProjects } from "../../Services/RQDashboardService";
// import moment from 'moment';
import {
  ArrowLeftOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import { dayjs } from "../../utils/dayjs";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import { AddEditApis } from "../../Services/RQTaskService";
interface ISearch {
  project: {
    search: undefined;
  };
  sprint: {
    search: undefined;
  };
}
const SprintList = () => {
  const user = useSelector(userData);
  const { useProject } = AddEditApis;
  const [payload, setPayload] = useState({
    projectId: "",
    length: 150,
  });
  const {
    isLoading: isLoading,
    data: sprintDataList,
    isFetching: isFetching,
  } = useGetSprintList(payload);
  const [show, setShow] = useState("");
  const [sprintData, setSprintData] = useState<any>();

  const getSprintList = useMemo(() => {
    return sprintDataList?.result?.map((sprint: any, index: any) => ({
      key: index,
      ...sprint,
    }));
  }, [sprintDataList]);
  const onEdit = (values: any) => {
    // console.log("...values",values);
    let val = {
      ...values,
      fromTo: [dayjs(values?.startDate), dayjs(values?.endDate)],
      // startDate: dayjs(values?.startDate),
      // endDate: dayjs(values?.endDate),
    };
    setSprintData({ ...sprintData, data: { ...val } });
    setShow("AddEditSprint");
    // setPayload({ ...payload, projectId: val?.value });
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "9%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => text,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "description",
      render: (text: any, record: any) => (
        <>
          <Row justify={"end"}>
            <FormOutlined
              className="capTask-edit-btn"
              onClick={() => onEdit(record)}
            />
          </Row>
        </>
      ),
    },
  ];
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  const [searchFilter, setSearchFilter] = useState<ISearch>({
    project: {
      search: undefined,
    },
    sprint: {
      search: undefined,
    },
  });
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Sprint",
              },
            ]}
          />
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorTextPlaceholder: "#95a5a6",
              fontSize: 14,
              colorPrimaryHover: "none",
              fontSizeIcon: 10,
              paddingSM: 0,
              paddingXS: 0,
              paddingXXS: 0,
              lineWidth: 1,
              borderRadius: 0,
              borderRadiusLG: 0,
              borderRadiusSM: 0,
              borderRadiusXS: 0,
              controlPaddingHorizontal: 12,
              controlPaddingHorizontalSM: 12,
              controlHeight: 27,
              controlHeightLG: 27,

              algorithm: true,
              fontWeightStrong: 600,
            },
          },
        }}
      >
        <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
          <Col span={24}>
            <Row justify={"space-between"}>
              <Col span={2}>
                <Button
                  icon={<PlusOutlined />}
                  className="capTask-btn btn-clr-primary btn-primary-md mb-1"
                  size="small"
                  onClick={() => {
                    setShow("AddEditSprint");
                  }}
                >
                  Sprints
                </Button>
              </Col>
              <Col>
                <Select
                  style={{
                    backgroundColor: "#f3f4fa",
                    width: "250px",
                  }}
                  labelInValue
                  className="capTask-custom-select"
                  placeholder="Select a Project"
                  options={projectOptions}
                  showSearch
                  onSearch={(value) => {
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      project: {
                        search: value,
                      },
                    }));
                  }}
                  optionFilterProp="label"
                  onSelect={(val: any) => {
                    setSprintData(() => {
                      return {
                        ...sprintData,
                        name: val?.label,
                        projectId: val?.value,
                      };
                    });
                    setSearchFilter((pre: any) => ({
                      ...pre,
                      project: {
                        search: "",
                      },
                    }));
                    setPayload({ ...payload, projectId: val?.value });
                  }}
                  onBlur={() => {
                    setSprintData((pre: any) => ({
                      ...pre,
                      project: {
                        search: "",
                      },
                    }));
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24}>
            {isProjectPending ? (
              <TableSkeleton
                columns={columns}
                scroll={{ y: "75vh" }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
              />
            ) : (
              <Table
                className="capTask-custom-table mt-3 project-table"
                size="small"
                onChange={onChangeTable}
                pagination={{
                  showTotal: (total: any, range: any) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams?.start / listParams?.length + 1,
                  pageSize: listParams?.length,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
                scroll={{ y: 500 }}
                columns={columns}
                // dataSource={getSprintList}
                dataSource={getSprintList?.map((data: any, index: number) => ({
                  serial: index + 1,
                  key: index,
                  ...data,
                }))}
                locale={{
                  emptyText: (
                    <Typography.Title
                      level={4}
                      style={{ textAlign: "center", paddingTop: "47px" }}
                    >
                      <img
                        style={{ paddingTop: "40px", width: 140 }}
                        // src={process.env.PUBLIC_URL + "/noTask.svg"}
                        src={process.env.PUBLIC_URL + "/img-antd.svg"}
                        alt="No Task"
                      />

                      <div style={{ textAlign: "center" }}>
                        <h3
                          style={{
                            margin: 0,
                            fontFamily: "Open Sans",
                            fontSize: 16,
                            color: "rgb(72 81 110)",
                          }}
                        >
                          Please select project
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontFamily: "Open Sans",
                            fontSize: 16,
                            color: "rgb(72 81 110)",
                          }}
                        >
                          And Click the sprint button to get Project
                        </h3>
                      </div>
                    </Typography.Title>
                  ),
                }}
              />
            )}
          </Col>
        </Row>
      </ConfigProvider>
      {show === "AddEditSprint" && (
        <AddEditSprint
          onDismiss={() => {
            setShow("");
            setSprintData({ ...sprintData, data: undefined });
            // setPayload(payload);
          }}
          data={sprintData}
        />
      )}
    </>
  );
};

export default SprintList;
